import React, { useState } from "react";
import styled from "styled-components";
import { fetchData } from "lib/helpers/fetchData";

import { ConfirmModal } from "lib/components/Modal";

import { General } from "./settings/General";
import { Users } from "./settings/users";
import { Soc } from "./settings/soc";
import { Phish } from "./settings/Phish";
import { Vuln } from "./settings/Vuln";
import { Pentest } from "./settings/Pentest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faUsers,
  faShieldAlt,
  faBiohazard,
  faEnvelope,
  faNetworkWired,
  faTicketAlt,
  faBug,
  faUmbrella,
  faAddressBook,
  faDoorOpen,
  faComments,
  faProjectDiagram,
  faServer,
  faUserNinja,
  faUserShield,
  faLockOpen,
  faCookieBite,
  faFileContract,
  faHospitalUser,
} from "@fortawesome/free-solid-svg-icons";
import { Azure } from "./settings/Azure";
import { Umbrella } from "./settings/Umbrella";
import { Jira } from "./settings/Jira";
import { Qualys } from "./settings/Qualys";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { Contacts } from "./settings/Contacts";
import { JumpBox } from "./settings/JumpBox";
import { Notes } from "./settings/Notes";
import { Network } from "./settings/Network";
import { Hosts } from "./settings/Hosts";
import { PhishSim } from "./settings/PhishSim";
import { Auth } from "./settings/Auth";
import { Tracking } from "./settings/Tracking";
import { Mds } from "./settings/Mds";
import { useIsFeatureEnabled, flags } from "lib/feature-flagging/treatments";
import { Aimdr } from "apps/customers/components/settings/Aimdr";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const deleteCustomer = async (customerId, usingIdem) => {
  if (usingIdem) return await fetchData(`${getApiUrl("IDEM_API")}/organisations/${customerId}`, null, "DELETE");
  else return await fetchData(`${getApiUrl("ADMIN_API")}/customers/${customerId}`, null, "DELETE");
};

const StyledContentContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
`;

const StyledHeaderContainer = styled.div`
  padding: 2.2rem 1.5rem;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.disabledCardBackground};
  border-bottom: 1px solid ${(props) => props.theme.headerOutline};
  & > h1 {
    color: ${(props) => props.theme.text};
    margin: 0;
    flex: 1;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  & > .tabs {
    display: flex;
    flex-flow: column;
    width: 300px;
    border-right: 1px solid ${(props) => props.theme.headerOutline};
    background: ${(props) => props.theme.disabledCardBackground};
    padding-bottom: 2rem;
    max-height: calc(100% - 8.7rem);
    & > h1 {
      margin: 1.5rem 1.5rem;
      font-size: 1.5rem;
      color: ${(props) => props.theme.text};
    }
  }
`;

const StyledTab = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  transition: all 0.2s;
  background: ${(props) => (props.active ? props.theme.accentCardBackground : props.theme.disabledCardBackground)};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? props.theme.text : "inherit")};
  & > svg {
    margin-right: 1rem;
    color: ${(props) => props.theme.text};
  }
  &:hover {
    background: ${(props) => props.theme.cardBackground};
  }
  &:first-of-type {
    margin-left: 0;
  }
  .status {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > div {
      width: 0.65rem;
      height: 0.65rem;
      border-radius: 50%;
      background: red;
      &.enabled {
        background: green;
      }
    }
  }
  user-select: none;
`;

const StyledPaneContainer = styled(ScrollContainer)`
  flex: 1;
  padding: 3rem;
  height: calc(100% - 6rem);
  box-sizing: border-box;
  & > *:not(.spinner) {
    flex: 1;
  }
`;

const TABS = {
  OVERVIEW: "overview",
  USERS: "users",
  AUTHENTICATION: "auth",
  SOC: "soc",
  PHISH: "phish",
  VULN: "vuln",
  PHISH_SIM: "phish-sim",
  JIRA: "jira",
  UMBRELLA: "umbrella",
  QUALYS: "qualys",
  AZURE: "azure",
  CONTACTS: "contacts",
  JUMP_BOX: "jumpbox",
  GENERAL_NOTES: "generalNotes",
  NETWORK: "network",
  HOSTS: "hosts",
  PENTEST: "pentest,",
  MIXPANEL_TRACKING: "mixpanelTracking",
  MDS: "mds",
  AI_MDR: "aimdr",
};

export const SingleCustomer = ({ data = {}, reload, endCreation }) => {
  const [tab, setTab] = useState(TABS.GENERAL);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });

  const usingIdem = useIsFeatureEnabled(flags.MANAGE_ORGANISATIONS_WITH_IDEM);

  const handleDeleteCustomer = async () => {
    setIsDeleting(true);
    await deleteCustomer(data?.id, usingIdem);
    setIsDeleting(false);
    reload();
    endCreation();
  };

  const showDeletePrompt = () => setConfirmModal({ showModal: true, callback: handleDeleteCustomer });

  const renderTab = () => {
    switch (tab) {
      case TABS.GENERAL:
        return <General data={data} reload={reload} handleDelete={showDeletePrompt} />;
      case TABS.USERS:
        return <Users customerId={data.id} group={data.groupName} isUserManagementEnabled={data?.userManagement?.enabled === true} />;
      case TABS.AUTHENTICATION:
        return <Auth data={data} reload={reload} />;
      case TABS.MIXPANEL_TRACKING:
        return <Tracking data={data} reload={reload} />;
      case TABS.SOC:
        return <Soc data={data} reload={reload} />;
      case TABS.PHISH:
        return <Phish data={data} reload={reload} />;
      case TABS.VULN:
        return <Vuln data={data} reload={reload} />;
      case TABS.PHISH_SIM:
        return <PhishSim data={data} reload={reload} />;
      case TABS.PENTEST:
        return <Pentest data={data} reload={reload} />;
      case TABS.MDS:
        return <Mds data={data} reload={reload} />;
      case TABS.AI_MDR:
        return <Aimdr data={data} reload={reload} />;
      case TABS.AZURE:
        return <Azure data={data} reload={reload} />;
      case TABS.UMBRELLA:
        return <Umbrella data={data} reload={reload} />;
      case TABS.JIRA:
        return <Jira data={data} reload={reload} />;
      case TABS.QUALYS:
        return <Qualys data={data} reload={reload} />;
      case TABS.CONTACTS:
        return <Contacts customer={data} reload={reload} />;
      case TABS.JUMP_BOX:
        return <JumpBox customerId={data.id} data={data} reload={reload} />;
      case TABS.GENERAL_NOTES:
        return <Notes customerId={data.id} data={data} reload={reload} />;
      case TABS.NETWORK:
        return <Network customerId={data.id} data={data} reload={reload} />;
      case TABS.HOSTS:
        return <Hosts customerId={data.id} hosts={data.wiki.hosts} reload={reload} />;
      default:
        return null;
    }
  };

  const renderAppStatus = (app) => {
    const enabled = data?.apps?.[app]?.enabled;
    return (
      <div className="status">
        <div className={enabled ? "enabled" : "disabled"} />
      </div>
    );
  };

  return (
    <StyledContentContainer>
      <StyledHeaderContainer>
        <h1>{data.name}</h1>
      </StyledHeaderContainer>
      <StyledContainer>
        <ScrollContainer className="tabs">
          <StyledTab active={tab === TABS.GENERAL} onClick={() => setTab(TABS.GENERAL)}>
            <FontAwesomeIcon icon={faCalendarDay} /> Overview
          </StyledTab>
          <StyledTab active={tab === TABS.USERS} onClick={() => setTab(TABS.USERS)}>
            <FontAwesomeIcon icon={faUsers} /> Users
          </StyledTab>
          <StyledTab active={tab === TABS.AUTHENTICATION} onClick={() => setTab(TABS.AUTHENTICATION)}>
            <FontAwesomeIcon icon={faUserShield} /> Authentication
          </StyledTab>
          <StyledTab active={tab === TABS.MIXPANEL_TRACKING} onClick={() => setTab(TABS.MIXPANEL_TRACKING)}>
            <FontAwesomeIcon icon={faCookieBite} /> Mixpanel Tracking
          </StyledTab>
          <h1>Services</h1>
          <StyledTab active={tab === TABS.SOC} onClick={() => setTab(TABS.SOC)}>
            <FontAwesomeIcon icon={faShieldAlt} /> SOC {renderAppStatus("soc")}
          </StyledTab>
          <StyledTab active={tab === TABS.VULN} onClick={() => setTab(TABS.VULN)}>
            <FontAwesomeIcon icon={faBiohazard} /> Vuln {renderAppStatus("vuln")}
          </StyledTab>
          <StyledTab active={tab === TABS.PHISH} onClick={() => setTab(TABS.PHISH)}>
            <FontAwesomeIcon icon={faEnvelope} /> Phish {renderAppStatus("phish")}
          </StyledTab>
          <StyledTab active={tab === TABS.PHISH_SIM} onClick={() => setTab(TABS.PHISH_SIM)}>
            <FontAwesomeIcon icon={faUserNinja} /> Phishing Simulation {renderAppStatus("phish-sim")}
          </StyledTab>
          <StyledTab active={tab === TABS.PENTEST} onClick={() => setTab(TABS.PENTEST)}>
            <FontAwesomeIcon icon={faLockOpen} /> Pentest {renderAppStatus("pentest")}
          </StyledTab>
          <StyledTab active={tab === TABS.MDS} onClick={() => setTab(TABS.MDS)}>
            <FontAwesomeIcon icon={faFileContract} /> MDS Service {renderAppStatus("mds")}
          </StyledTab>
          <StyledTab active={tab === TABS.AI_MDR} onClick={() => setTab(TABS.AI_MDR)}>
            <FontAwesomeIcon icon={faHospitalUser} /> Automated Identity MDR {renderAppStatus("aimdr")}
          </StyledTab>
          <h1>Run Book</h1>
          <StyledTab active={tab === TABS.CONTACTS} onClick={() => setTab(TABS.CONTACTS)}>
            <FontAwesomeIcon icon={faAddressBook} /> Contacts
          </StyledTab>
          <StyledTab active={tab === TABS.JUMP_BOX} onClick={() => setTab(TABS.JUMP_BOX)}>
            <FontAwesomeIcon icon={faDoorOpen} /> Jump Box
          </StyledTab>
          <StyledTab active={tab === TABS.NETWORK} onClick={() => setTab(TABS.NETWORK)}>
            <FontAwesomeIcon icon={faProjectDiagram} /> Network
          </StyledTab>
          <StyledTab active={tab === TABS.HOSTS} onClick={() => setTab(TABS.HOSTS)}>
            <FontAwesomeIcon icon={faServer} /> Hosts
          </StyledTab>
          <StyledTab active={tab === TABS.GENERAL_NOTES} onClick={() => setTab(TABS.GENERAL_NOTES)}>
            <FontAwesomeIcon icon={faComments} /> Notes
          </StyledTab>
          <h1>Connectors</h1>
          <StyledTab active={tab === TABS.AZURE} onClick={() => setTab(TABS.AZURE)}>
            <FontAwesomeIcon icon={faNetworkWired} /> Azure
          </StyledTab>
          <StyledTab active={tab === TABS.JIRA} onClick={() => setTab(TABS.JIRA)}>
            <FontAwesomeIcon icon={faTicketAlt} /> Jira
          </StyledTab>
          <StyledTab active={tab === TABS.QUALYS} onClick={() => setTab(TABS.QUALYS)}>
            <FontAwesomeIcon icon={faBug} /> Qualys
          </StyledTab>
          <StyledTab active={tab === TABS.UMBRELLA} onClick={() => setTab(TABS.UMBRELLA)}>
            <FontAwesomeIcon icon={faUmbrella} /> Umbrella
          </StyledTab>
        </ScrollContainer>
        <StyledPaneContainer>{renderTab()}</StyledPaneContainer>
      </StyledContainer>
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </StyledContentContainer>
  );
};
