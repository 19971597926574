import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const Umbrella = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    const values = getFormValues(formRef.current);
    await fetchData(`${getApiUrl("ADMIN_API")}/customers/${data.id}/connectors/umbrella`, JSON.stringify({ ...values }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };
  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section>
          <Input name="umbrellaId" label="Umbrella Id" defaultValue={data.connectors.umbrella.umbrellaId} readOnly={!isEditMode} />
          <Input name="orgId" label="Org Id" defaultValue={data.connectors.umbrella.orgId} readOnly={!isEditMode} />
        </section>
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
    </div>
  );
};
