import { useState } from "react";
import moment from "moment";
import { Switch, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { InfiniteScroll, useInfiniteScroll } from "lib/components/InfiniteScroll";
import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";
import { SortableHeader } from "lib/components/SortableHeader";
import { Table } from "lib/components/Table";
import { LinkRow } from "lib/components/LinkRow";
import { WordBox } from "lib/components/WordBox";
import { StyledFilters } from "lib/styles/general";

import { CreateEngagement } from "./CreateEngagement";
import { SingleEngagement } from "./SingleEngagement";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const EngagementsList = () => {
  const history = useHistory();
  const [data, setData] = useState({ result: [] });
  const [params, setParams] = useState({ search: "", orderBy: "title", reverse: false });

  const url = `${getApiUrl("PENTEST_API")}/engagements`;
  const { loadItems, hasMore, isLoading, reload } = useInfiniteScroll({ url, params, data, setData });

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => {
      setParams((p) => ({ ...p, ...sort }));
    },
  };

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const handleSubmitNew = () => {
    history.push("/pentest/engagements/create-engagement");
  };

  return (
    <Switch>
      <ErrorHandledRoute path="/pentest/engagements" exact>
        <div style={{ width: "100%" }}>
          <StyledFilters>
            <Searchbar {...search} />
            <hr />
            <CTAButton onClick={handleSubmitNew}>Submit New</CTAButton>
          </StyledFilters>
          <InfiniteScroll loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
            <Table tableStyle={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <SortableHeader field="title" {...sort}>
                    Engagement Title
                  </SortableHeader>
                  <SortableHeader field="customer.name" {...sort} headerStyle={{ width: "18rem" }}>
                    Customer
                  </SortableHeader>
                  <th>Testers</th>
                  <th style={{ width: "18rem" }}>Dates</th>
                  <th style={{ width: "10rem" }}></th>
                </tr>
              </thead>
              <tbody>
                {data.result.map((engagement, i) => {
                  const toDates = engagement.testerDates.map((testerDate) => moment(testerDate.to).unix());
                  const fromDates = engagement.testerDates.map((testerDate) => moment(testerDate.from).unix());
                  let latestDate = Math.max(...toDates);
                  latestDate = moment.unix(latestDate).format("YYYY-MM-DD");
                  let earliestDate = Math.min(...fromDates);
                  earliestDate = moment.unix(earliestDate).format("YYYY-MM-DD");
                  const hasValidDates = moment(latestDate).isValid() && moment(earliestDate).isValid();
                  const hasStarted = moment(earliestDate).isBefore(moment());
                  const hasFinished = moment(latestDate).isBefore(moment());
                  const isInProgress = hasStarted && !hasFinished;
                  const hasTesters = engagement.testerDates.length;
                  return (
                    <LinkRow key={i} path={`/pentest/engagements/engagement/${engagement.customer.id}/${engagement.id}`}>
                      <td style={{ paddingRight: "0" }}>{engagement.title}</td>
                      <td style={{ paddingRight: "0" }}>{engagement.customer?.name}</td>
                      <td>{hasTesters ? engagement.testerDates.map((td) => td.name).join(", ") : "No testers yet"}</td>
                      <td>{hasValidDates ? `${earliestDate} - ${latestDate}` : "No dates yet"}</td>
                      <td>
                        {hasValidDates &&
                          (isInProgress ? (
                            <WordBox color="#01ad01">In progress...</WordBox>
                          ) : hasFinished ? (
                            <WordBox>Finished</WordBox>
                          ) : (
                            <WordBox color="#ce8d00">Not yet started</WordBox>
                          ))}
                      </td>
                    </LinkRow>
                  );
                })}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      </ErrorHandledRoute>
      <ErrorHandledRoute path="/pentest/engagements/create-engagement">
        <CreateEngagement reload={reload} />
      </ErrorHandledRoute>
      <ErrorHandledRoute path="/pentest/engagements/engagement/:customerId/:engagementId">
        <SingleEngagement reload={reload} />
      </ErrorHandledRoute>
    </Switch>
  );
};
