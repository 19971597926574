import React, { useRef } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faCog, faHamburger } from "@fortawesome/free-solid-svg-icons";

import { LogoCircle } from "lib/assets/LogoCircle";
import { redirectToLogout } from "auth/helpers";
import { Select } from "lib/components/Select";
import { useRegion } from "lib/components/RegionProvider";

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  border-bottom: 1px solid ${(props) => props.theme.headerOutline};
  max-width: 100vw;
  background: ${(props) => props.theme.headerBackground};

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      @media (max-width: ${(props) => props.theme.mobileWidth}) {
        z-index: 101;
        display: none;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        top: calc(4rem + 1px);
        a {
          background: ${(props) => props.theme.headerBackground};
        }
      }
    }
  }

  input {
    display: none;
    &:checked + div {
      display: block;
    }
  }
  label {
    display: none;
    cursor: pointer;
    @media (max-width: ${(props) => props.theme.mobileWidth}) {
      display: block;
    }
    color: ${(props) => props.theme.text};
    margin: 0 2rem;
    font-size: 1.5rem;
  }

  h2 {
    margin: 0;
    color: ${(props) => props.theme.accentText};
    font-size: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => (props.active === "true" ? props.theme.accentText : props.theme.text)};
  height: 4rem;
  padding: 0 1.8rem;
  font-size: 1.33rem;
  font-weight: ${(props) => props.active === "true" && "bold"};
  border: 0 solid ${(props) => props.theme.headerOutline};
  border-right-width: ${(props) => props.right};
  border-left-width: ${(props) => props.left};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  &:hover {
    background: ${(props) => props.theme.primaryButtonHover};
  }
  svg {
    font-size: 1.3rem;
    &.circle {
      height: calc(100% - 10px);
    }
  }
`;

export const Header = ({ appLink, appName, routes, showRouteLinks }) => {
  const menuRef = useRef(null);

  const { pathname } = useLocation();
  const [, app, path] = pathname.split("/");
  const currentPath = `/${[app, path].join("/")}`;

  const { regionUrl, changeRegion, allRegions } = useRegion();

  const handleRegionChange = (event) => {
    changeRegion(event.target.value);
  };

  return (
    <StyledHeaderContainer>
      <div>
        <StyledLink to="/" right="1px">
          <LogoCircle />
        </StyledLink>
        <StyledLink to={appLink}>
          <h2>{appName}</h2>
        </StyledLink>
      </div>
      <label htmlFor="menu">
        <FontAwesomeIcon icon={faHamburger} />
      </label>
      <input id="menu" type="checkbox" ref={menuRef} />
      <div onClick={() => (menuRef.current.checked = false)}>
        {showRouteLinks &&
          routes
            .filter((r) => !r.isHidden)
            .map((route, i) => (
              <StyledLink key={i} right="1px" to={route.link} active={`${currentPath === route.link}`}>
                {route.name}
              </StyledLink>
            ))}
        <Select name="region" value={regionUrl} onChange={handleRegionChange}>
          {allRegions.map((instance) => (
            <option key={instance.name} value={instance.url}>
              {instance.name}
            </option>
          ))}
        </Select>
        <StyledLink right="1px" to={appLink + "/settings"} active={`${currentPath === appLink + "/settings"}`}>
          <FontAwesomeIcon icon={faCog} />
        </StyledLink>
        <StyledLink to="#" onClick={redirectToLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </StyledLink>
      </div>
    </StyledHeaderContainer>
  );
};
