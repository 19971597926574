import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { Tabs } from "lib/components/Tabs";
import { ConfirmModal } from "lib/components/Modal";
import { Card } from "lib/components/Card";
import { MarkdownEditor } from "lib/components/MarkdownEditor";
import { CTAButton } from "lib/components/CTAButton";

import { EditRelease } from "./EditRelease";
import { fetchData } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";

const StyledContainer = styled(Card)`
  margin-bottom: 3rem;

  .header {
    display: flex;
    align-items: center;
    height: 3rem;
    padding: 0.5rem 0 0.5rem 1rem;
    width: 100%;
    box-sizing: border-box;
    & > h2 {
      flex: 1;
      font-size: 1.8rem;
      margin: 0;
    }
    & > hr {
      margin: 0 1rem;
      border: none;
      height: 2.5rem;
      width: 2px;
      background: ${(props) => props.theme.headerOutline};
    }
    & > h3 {
      font-size: 1.5rem;
      color: ${(props) => props.theme.lightText};
      margin: 0;
    }
    & > button {
      margin: 0;
      margin-left: 1rem;
    }

    & + div {
      margin-top: 1rem;
    }
  }

  .text-content {
    width: 100%;
  }
`;

export const SingleRelease = ({ release, reload }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);

  const { id, title, version, date, clarityContent, slackContent } = release;

  const showEdit = () => setShowEditModal(true);
  const hideEdit = () => setShowEditModal(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await fetchData(`${getApiUrl("ADMIN_API")}/releases/${id}`, null, "DELETE");
    setIsDeleting(false);
    setConfirmModal({ showModal: false, callback: null });
    reload();
  };

  const refresh = () => {
    hideEdit();
    reload();
  };

  return (
    <>
      <EditRelease show={showEditModal} hide={hideEdit} release={release} refresh={refresh} />
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
      <StyledContainer>
        <div className="header">
          <h2>{title}</h2>
          <h3>{version}</h3>
          <hr />
          <h3>{moment(date).format("YYYY-MM-DD")}</h3>
          <CTAButton isSecondary onClick={showEdit}>
            Edit
          </CTAButton>
          <CTAButton onClick={() => setConfirmModal({ showModal: true, callback: handleDelete })}>Delete</CTAButton>
        </div>
        <Tabs subtle noPad>
          <div className="text-content" name="Clarity Content">
            <MarkdownEditor name="clarity-content" defaultValue={clarityContent} hideButtons readOnly subtle />
          </div>
          <div className="text-content" name="Slack Content">
            <MarkdownEditor name="slack-content" defaultValue={slackContent} hideButtons readOnly subtle />
          </div>
        </Tabs>
      </StyledContainer>
    </>
  );
};
