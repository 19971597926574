import React, { useState, useRef } from "react";
import { getFormValues } from "lib/helpers/getFormValues";
import { fetchData } from "lib/helpers/fetchData";

import { StyledForm } from "lib/styles/general";
import { CheckboxButton } from "lib/components/Checkbox";

import { Modal } from "lib/components/Modal";
import { CTAButton } from "lib/components/CTAButton";
import { getApiUrl } from "lib/helpers/getApiUrl";

const setRole = (roles, role, value) => {
  if (value) {
    roles.push(role);
  } else {
    roles = roles.filter(r => r !== role);
  }
  return roles;
}

export const EditPermissionsModal = ({ showModal, hide, customer = {}, customerUser = {}, reload }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const handleSubmit = async () => {
    const selectedPermissions = getFormValues(formRef.current);

    const updatedPermissions = {
      soc: { enabled: selectedPermissions.soc },
      vuln: { enabled: selectedPermissions.vuln },
      phish: { enabled: selectedPermissions.phish },
      "phish-sim": { enabled: selectedPermissions["phish-sim"] },
      pentest: { enabled: selectedPermissions.pentest },
      mds: { enabled: selectedPermissions.mds },
    };
    
    let updatedRoles = [...customerUser.roles, "user"];
    updatedRoles = setRole(updatedRoles, "customer-admin", selectedPermissions.isCustomerAdmin);
    updatedRoles = [...new Set(updatedRoles)];

    let updatedCustomerUser = customerUser;
    updatedCustomerUser.apps = updatedPermissions;
    updatedCustomerUser.roles = updatedRoles;

    setIsLoading(true);
    await fetchData(
      `${getApiUrl("ADMIN_API")}/user/${customerUser.id}/customers/permissions`,
      JSON.stringify(updatedCustomerUser),
      "PUT"
    );
    setIsLoading(false);
    reload();
    hide();
  };
  return (
    <Modal title={customer.name} showModal={showModal} hide={hide} width="40rem" aria-label="Edit permissions">
      <StyledForm ref={formRef}>
        <section style={{ paddingTop: 0 }}>
          <h3>App Permissions</h3>
          <CheckboxButton name="soc" subtle label={`Soc`} defaultChecked={customerUser?.apps?.["soc"]?.enabled} />
          <CheckboxButton name="vuln" subtle label={`Vuln`} defaultChecked={customerUser?.apps?.["vuln"]?.enabled} />
          <CheckboxButton name="phish" subtle label={`Phish`} defaultChecked={customerUser?.apps?.["phish"]?.enabled} />
          <CheckboxButton
            name="phish-sim"
            subtle
            label={`Phishing Simulation`}
            defaultChecked={customerUser?.apps?.["phish-sim"]?.enabled}
          />
          <CheckboxButton name="pentest" subtle label={`Pentest`} defaultChecked={customerUser?.apps?.["pentest"]?.enabled} />
          <CheckboxButton name="mds" subtle label={`MDS`} defaultChecked={customerUser?.apps?.["mds"]?.enabled} />
        </section>
        <section>
          <h3>Admin Rights</h3>
          <CheckboxButton
            name="isCustomerAdmin"
            subtle
            label={`Customer Admin?`}
            defaultChecked={customerUser?.roles?.includes("customer-admin")}
          />

          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
            <CTAButton isSecondary onClick={hide}>
              Cancel
            </CTAButton>
            <CTAButton onClick={handleSubmit} isLoading={isLoading}>
              Save
            </CTAButton>
          </div>
        </section>
      </StyledForm>
    </Modal>
  );
};
