import { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";

import { TextArea } from "lib/components/TextArea";
import { fetchData, Spinner, Error } from "lib/helpers/fetchData";
import { CreateComment } from "./CreateComment";
import { useUser } from "auth";
import { ConfirmModal } from "lib/components/Modal";
import { getApiUrl } from "lib/helpers/getApiUrl";

const updateComment = async ({ id, targetId, text }) => {
  return await fetchData(`${getApiUrl("PENTEST_API")}/comments/${targetId}/${id}`, JSON.stringify({ text }), "PUT");
};

const deleteComment = async ({ id, targetId }) => {
  return await fetchData(`${getApiUrl("PENTEST_API")}/comments/${targetId}/${id}`, null, "DELETE");
};

const StyledComment = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
  opacity: ${(props) => (props.recben ? 0.4 : 1)};
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }

  & > div:first-child {
    flex: 1;
    & > p {
      word-break: break-word;
      padding-left: 0.75rem;
      border-left: 2px solid ${(props) => props.theme.headerOutline};
      box-sizing: border-box;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -10rem;
  }

  textarea {
    width: 100% !important;
    height: ${(props) => (props.create ? "75px" : "50px")};
    min-height: ${(props) => (props.create ? "75px" : "50px")};
    padding: 0.5rem;
    background: transparent;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.headerOutline};
    color: inherit;
    font: inherit;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    outline: none;
    resize: none;
    margin-right: 0.5rem;
    transition: border 0.3s;
    &:hover,
    &:focus-within {
      border: 1px solid ${(props) => props.theme.secondaryButtonColor};
      resize: auto;
    }
  }

  :first-child {
    flex-flow: column;
    align-items: flex-end;
    textarea {
      margin: 0;
    }
    button {
      position: unset;
      margin: 1rem 0 0 0;
    }
  }

  svg {
    cursor: pointer;
    margin: 0 0 0 6px;
    font-size: 1rem;
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.25rem;
    padding: 0.45rem;
    background: ${(props) => props.theme.cardBackground};
    transition: background 0.3s;
    &:hover {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }

  p {
    margin: 4px 0;
    font-size: 1.5rem;
  }
`;

const StyledCommentHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1.33rem;
    margin: 0 0.5rem 0 0.5rem;
    :first-child {
      margin-left: 0;
    }
  }
  p {
    margin: 0;
  }
  section {
    flex: 1;
    border-bottom: none !important;
    padding: 0 !important;
  }
  div {
    display: flex;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  min-height: 10rem;
  margin: 0.5rem auto 1.5rem;
  hr {
    border: none;
    background: ${(props) => props.theme.headerOutline};
    height: 1px;
    margin: 1.5rem 0.5rem;
  }
  p.no-length {
    font-size: 1.5rem;
    color: ${(props) => props.theme.mediumText};
    text-align: center;
    margin: 0;
  }
`;

export const CommentsList = ({ data, isLoading, error, reload, targetId }) => {
  const renderComments = () => {
    if (isLoading) return <Spinner />;
    if (error) return <Error message={error.message} />;

    const comments = data?.result?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))) || [];
    if (!comments?.length) return <p className="no-length">No comments added yet</p>;

    return (
      <>
        {comments.map((comment) => (
          <Comment key={comment.id} {...comment} reload={reload} targetId={targetId} />
        ))}
      </>
    );
  };

  return (
    <StyledContainer>
      <CreateComment targetId={targetId} reload={reload} />
      <hr />
      {renderComments()}
    </StyledContainer>
  );
};

const Comment = ({ text, user, id, createdAt, updatedAt, reload, targetId }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [localText, setLocalText] = useState(text);

  const textareaRef = useRef(null);

  const { user: currentUser } = useUser();
  const isOwnComment = currentUser?.id?.toLowerCase() === user?.id;
  const didEdit = createdAt !== updatedAt;

  const handleChange = (e) => {
    setLocalText(e.target.innerText || e.target.value);
    e.target.style.height = `${e.target.scrollHeight || 10}px`;
  };

  const handleSubmit = async () => {
    setIsEditMode(false);
    await updateComment({ id, targetId, text: localText });
    reload();
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteComment({ id, targetId });
    setIsDeleting(false);
    setConfirmModal({ showModal: false, callback: null });
    reload();
  };

  const showDeletePrompt = () => setConfirmModal({ showModal: true, callback: handleDelete });

  useLayoutEffect(() => {
    if (isOwnComment) handleChange({ target: textareaRef.current });
  }, [isOwnComment]);

  return (
    <>
      <StyledComment own={isOwnComment}>
        <div>
          <StyledCommentHeader>
            <h3>{user.displayName}</h3>
            <p>-</p>
            <h3>{`${moment(createdAt).fromNow()} (${moment(createdAt).format("DD/MM/YYYY - HH:mm")})`}</h3>
            {didEdit && (
              <>
                {" - "}
                <h3>Edited</h3>
              </>
            )}
            <section />
            <div>
              {!isEditMode && isOwnComment && <FontAwesomeIcon icon={faEdit} onClick={() => setIsEditMode(true)} />}
              {localText !== text && <FontAwesomeIcon icon={faSave} onClick={handleSubmit} />}
              {isOwnComment && <FontAwesomeIcon icon={faTrash} onClick={showDeletePrompt} />}
            </div>
          </StyledCommentHeader>
          <TextArea ref={textareaRef} readOnly={!isEditMode} defaultValue={text} onChange={handleChange} />
        </div>
      </StyledComment>
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </>
  );
};
