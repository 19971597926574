import { CTAButton } from "lib/components/CTAButton";
import { CheckboxButton } from "lib/components/Checkbox";
import { KeyValue } from "lib/components/KeyValue";
import { fetchData } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";
import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { useRef, useState } from "react";

export const Mds = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    let { enabled } = getFormValues(formRef.current);
    await fetchData(`${getApiUrl("ADMIN_API")}/customers/${data?.id}/apps/mds`, JSON.stringify({ enabled }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleExit = () => setIsEditMode(false);

  const showEditModeLabel = () => data?.apps?.mds?.enabled ? "Enabled" : "Disabled"

  return (
    <div style={{ position: "relative" }}>
      <StyledForm ref={formRef}>
        <section style={{ paddingTop: 0 }}>
          <KeyValue
            text="Service Status"
            wrap={false}
            value={
              <CheckboxButton
                name="enabled"
                subtle
                showWhenNull
                label={!isEditMode ? showEditModeLabel() : "Is Enabled?"}
                defaultChecked={!!data?.apps?.mds?.enabled}
                readOnly={!isEditMode}
              />
            }
          />
        </section>
      </StyledForm>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
      {isEditMode ? (
        <>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </>
      ):(
        <CTAButton onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      </div>
    </div>
  );
};
