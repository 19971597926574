import { Input } from "lib/components/Input";
import { ErrorModal } from "lib/components/Modal";
import { Modal } from "lib/components/Modal";
import { Select } from "lib/components/Select";
import { fetchData } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";
import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { useRef, useState } from "react";

export const EditClient = ({ showModal, hide, client = {}, reload }) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleEdit = () => {
    const values = getFormValues(formRef.current);
    const tier = parseInt(values["tier"]);

    setIsLoading(true);

    fetchData(`${getApiUrl("SERVICE_DELIVERY_API")}/clients/${client.id}`, JSON.stringify({ tier }), "PUT")
      .then(() => hide())
      .then(() => reload())
      .catch((e) => setError(e.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      showModal={showModal}
      hide={hide}
      isLoading={isLoading}
      width="50rem"
      subtitle={`Edit ${client.name}`}
      actionText="Save"
      actionCommand={handleEdit}
    >
      {error !== null && <ErrorModal error={error} hide={() => setError(null)} />}
      <StyledForm ref={formRef}>
        <Input label="Tier">
          <Select name="tier" defaultValue={client.tier.toString()}>
            <option value="-1">Unset</option>
            <option value="1">Very high</option>
            <option value="2">High</option>
            <option value="3">Medium</option>
            <option value="4">Low</option>
            <option value="5">Very Low</option>
          </Select>
        </Input>
      </StyledForm>
    </Modal>
  );
};
