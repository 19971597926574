import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";

import { Card } from "lib/components/Card";
import { Navbar } from "lib/components/Navbar";
import { CampaignForm } from "../CampaignForm";
import { getApiUrl } from "lib/helpers/getApiUrl";

const fetchCampaign = async ({ reportId }) => {
  return await fetchData(`${getApiUrl("REPORTING_API")}/phish-sim/reports/${reportId}`);
};

export const SingleCampaign = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { id } = useParams();

  const { data, isLoading, error, reload } = useFetch(fetchCampaign, { reportId: id });

  let paths = [
    { text: "Campaigns", link: "/phish-sim/campaigns" },
    { text: data?.result?.title, link: "#" },
  ];

  const refresh = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsEditMode(false);
    reload();
  };

  return (
    <>
      <Navbar paths={paths} />
      <Card>
        {isLoading && <Spinner />}
        {error && <Error message={error} />}
        {data && <CampaignForm campaign={data.result} refresh={refresh} isEditMode={isEditMode} setIsEditMode={setIsEditMode} />}
      </Card>
    </>
  );
};
