import React from "react";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";
import { Select } from "lib/components/Select";
import { KeyValue } from "lib/components/KeyValue";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const fetchCustomers = async ({ data } = {}) => {
  if (data) return data;
  let customers = await fetchData(`${getApiUrl("ADMIN_API")}/customers`);
  customers.result = customers.result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  return customers;
};

export const CustomerPicker = ({ defaultValue = {}, readOnly, name = "customer", label = "Customer", data: _data, setCustomer, style = {} }) => {
  const { data, isLoading, error } = useFetch(fetchCustomers, { data: _data });

  const handleChange = (e) => {
    setCustomer && setCustomer(e.target.value);
  };

  if (isLoading && !_data) return <Spinner />;
  if (error) return <Error message={error} />;

  return (
    <KeyValue
      text={label}
      wrap={false}
      value={
        <Select style={{ width: "20rem", marginTop: "0.3rem", ...style }} name={name} defaultValue={defaultValue?.id} readOnly={readOnly} onChange={handleChange}>
          <option value="">Select Customer</option>
          {(_data || data)?.result?.map((c) => (
            <option value={c.id} key={c.id}>
              {c.name}
            </option>
          ))}
        </Select>
      }
    />
  );
};
