import React from "react";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";

import { trimWord } from "lib/helpers/trimWord";
import { Table } from "lib/components/Table";
import { LinkRow } from "lib/components/LinkRow";
import { getApiUrl } from "lib/helpers/getApiUrl";

const loadTickets = async () => {
  return await fetchData(
    `${getApiUrl("PHISH_API")}/rules`
  );
};

export const ListRules = () => {
  const { data, error, isLoading } = useFetch(loadTickets);
  if (isLoading) return <Spinner />;
  if (error || data?.success === false) return <Error message={error} />;
  
  return (
    <Table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Confidence</th>
          <th>Description</th>
          <th style={{ textAlign: "right" }}>Tags</th>
          <th style={{ textAlign: "right" }}>Targets</th>
          <th>Enabled</th>
        </tr>
      </thead>
      <tbody>
        {data.result.length > 0 ? (
          data.result.map(rule => (
            <LinkRow key={rule.id} path={`/phish/rules/${rule.id}`}>
              <td>{rule.type}</td>
              <td>{rule.confidence}</td>
              <td>{trimWord(rule.description, 60)}</td>
              <td style={{ textAlign: "right" }}>{rule.tags?.join(", ")}</td>
              <td style={{ textAlign: "right" }}>{rule.targets?.join(", ")}</td>
              <td>{`${rule.enabled}`}</td>
            </LinkRow>
          ))
        ) : (
          <tr className="no-results-row">
            <td colSpan="70" className="no-results-text">
              <p>We have no rules matching this criteria</p>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
