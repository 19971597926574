import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "./lib/components/ThemeProvider";
import { GlobalStyle } from "./lib/styles/global";

import { AuthLayer } from "./auth";

import { Home } from "./apps/home";
import { Phish } from "./apps/phish";
import { Vuln } from "./apps/vuln";
import { Customers } from "./apps/customers";
import { PenTest } from "./apps/pentest";
import { ReleasePublishing } from "./apps/release-publishing";
import { Soc } from "./apps/soc";
import { PhishSim } from "apps/phish-sim";
import { Brain } from "./apps/brain";
import { Consulting } from "./apps/consulting";
import { DocumentHub } from "./apps/document-hub";
import { ServiceDeliveryPortfolio } from "apps/service-delivery-portfolio";
import { FeatureFlagProvider } from "lib/feature-flagging/FeatureFlagProvider";
import { RegionProvider } from "lib/components/RegionProvider";

const App = () => (
  <Router>
    <FeatureFlagProvider>
      <ThemeProvider>
        <AuthLayer>
          <RegionProvider>
            <Route path="/phish" component={Phish} />
            <Route path="/vuln" component={Vuln} />
            <Route path="/phish-sim" component={PhishSim} />
            <Route path="/customers" component={Customers} />
            <Route path="/pentest" component={PenTest} />
            <Route path="/release-publishing" component={ReleasePublishing} />
            <Route path="/soc" component={Soc} />
            <Route path="/" exact component={Home} />
            <Route path="/brain" component={Brain} />
            <Route path="/consulting" component={Consulting} />
            <Route path="/document-hub" component={DocumentHub} />
            <Route path="/service-delivery-portfolio" component={ServiceDeliveryPortfolio} />
          </RegionProvider>
        </AuthLayer>
        <GlobalStyle />
      </ThemeProvider>
    </FeatureFlagProvider>
  </Router>
);

export default App;
