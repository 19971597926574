import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";
import { Table } from "lib/components/Table";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { addParamsToUrl } from "lib/helpers/addParamsToUrl";
import { Modal } from "lib/components/Modal";
import { KeyValue } from "lib/components/KeyValue";
import { getApiUrl } from "lib/helpers/getApiUrl";

const loadActivityDay = async ({ day, type, excludeQC, removeDuplicates, customerId }) => {
  const startDate = moment(day).startOf("day").utc(true).toISOString();
  const endDate = moment(day).endOf("day").utc(true).toISOString();
  const params = {
    startDate,
    endDate,
    customerId,
    types: [type.id],
    excludeCustomerId: excludeQC ? process.env.REACT_APP_QC_ID : undefined,
    removeDuplicates: removeDuplicates ? true : undefined,
  };
  const url = new URL(`${getApiUrl("ADMIN_API")}/activities`);
  addParamsToUrl(url, params);
  return fetchData(url.toString());
};

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .scroller {
    flex: 1;
    max-height: 100%;
  }
`;

export const SingleDayActivities = ({ type, data, index, options }) => {
  if (index === undefined) return <div style={{ height: "100%" }} />;
  const day = moment.utc(data?.[index]?.date)?.format("YYYY-MM-DD");
  return <SingleDay key={index} type={type} day={day} options={options} />;
};

const SingleDay = ({ day, type, options }) => {
  const { data, isLoading, error } = useFetch(loadActivityDay, { day, type, ...options });
  const [activeActivity, setActiveActivity] = useState(null);

  return (
    <>
      <StyledContainer>
        {isLoading && <Spinner />}
        {error && <Error message={error} />}
        {data && (
          <ScrollContainer className="scroller">
            <Table style={{ border: "none" }}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>User</th>
                  <th>Customer</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {data.result.length ? (
                  data.result.map((activity) => (
                    <tr key={activity.id} style={{ cursor: "pointer" }} onClick={() => setActiveActivity(activity)}>
                      <td>{activity.type}</td>
                      <td>
                        {activity.user.displayName} ({activity.user.email})
                      </td>
                      <td>
                        {activity.customer.code} ({activity.customer.name})
                      </td>
                      <td>{moment.unix(activity.created).utc(true).format("HH:mm a")}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="no-results-row">
                    <td colSpan={4} className="no-results-text">
                      No activities found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ScrollContainer>
        )}
      </StyledContainer>
      {activeActivity && <SingleActivity activeActivity={activeActivity} setActiveActivity={setActiveActivity} />}
    </>
  );
};

const SingleActivity = ({ activeActivity, setActiveActivity }) => {
  return (
    <Modal style={{ paddingBottom: "1.5rem" }} width="50rem" hide={() => setActiveActivity(null)}>
      <KeyValue inverseStyle text="Type" value={activeActivity.type} />
      <KeyValue inverseStyle text="Target ID" value={activeActivity.targetId} />
      <KeyValue inverseStyle text="Actioned by" value={`${activeActivity.user.displayName} (${activeActivity.user.email})`} />
      <KeyValue inverseStyle text="Customer" value={activeActivity.customer.name} />
      <KeyValue inverseStyle text="Date" value={moment.unix(activeActivity.created).format("YYYY-MM-DD HH:mm")} />
      <KeyValue inverseStyle text="Activity" value={activeActivity.message} lineLimit={0} />
    </Modal>
  );
};
