import React, { useState } from "react";
import moment from "moment";

import { Table } from "lib/components/Table";
import { LinkRow } from "lib/components/LinkRow";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { getArtifactContent } from "lib/helpers/getArtifactContent";
import { SortableHeader } from "lib/components/SortableHeader";
import { useDebouncedCallback } from "use-debounce";
import { useInfiniteScroll, InfiniteScroll } from "lib/components/InfiniteScroll";
import styled from "styled-components";
import { Searchbar } from "lib/components/Searchbar";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const ListArtifacts = () => {
  const url = `${getApiUrl("PHISH_API")}/artifacts`;
  return <ListArtifactsComponent url={url} />;
};

const StyledSearchbar = styled(Searchbar)`
  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    margin: 1.5rem 1rem 0.5rem;
    width: calc(100% - 2rem);
    & + .table {
      margin-left: 2rem;
      width: calc(100% - 2rem);
    }
  }
`;

const ListArtifactsComponent = ({ url }) => {
  const [data, setData] = useState({ result: [] });
  const [params, setParams] = useState({ search: "", orderBy: "fid", reverse: true, pageSize: 40 });

  const { loadItems, hasMore, isLoading } = useInfiniteScroll({ url, params, data, setData });

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((p) => ({ ...p, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => setParams((p) => ({ ...p, ...sort })),
  };

  const filters = <StyledSearchbar {...search} />;

  return (
    <InfiniteScroll filters={filters} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
      <Table>
        <thead>
          <tr>
            <SortableHeader field="fid" {...sort}>
              ID
            </SortableHeader>
            <SortableHeader field="type" {...sort}>
              Type
            </SortableHeader>
            <th>Info</th>
            <SortableHeader field="lastSeen" {...sort}>
              Last Seen
            </SortableHeader>
            <SortableHeader field="firstSeen" {...sort}>
              First Seen
            </SortableHeader>
            <SortableHeader field="severity.score" {...sort} style={{ justifyContent: "flex-end" }}>
              Severity
            </SortableHeader>
          </tr>
        </thead>
        <tbody>
          {data.result.map((artifact, i) => (
            <LinkRow key={i} path={`/phish/artifacts/${artifact.id}`}>
              <td style={{ maxWidth: "100px" }}>{artifact.fid}</td>
              <td style={{ maxWidth: "50px", paddingRight: "0px" }}>{artifact.type}</td>
              <td style={{ maxWidth: "500px" }}>{getArtifactContent(artifact)}</td>
              <td style={{ maxWidth: "120px" }}>{moment(artifact.lastSeen).format("HH:mm - DD/MM/YYYY")}</td>
              <td style={{ maxWidth: "120px" }}>{moment(artifact.firstSeen).format("HH:mm - DD/MM/YYYY")}</td>
              <td
                style={{
                  maxWidth: "160px",
                  textAlign: "right",
                  textTransform: "capitalize",
                  color: getColorFromSeverity(artifact.severity),
                }}
              >
                {`${artifact.severity?.text} ${artifact.severity?.score > 0 ? `(${artifact.confidence?.text})` : ""} `}
              </td>
            </LinkRow>
          ))}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
};
