import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { Select } from "lib/components/Select";
import { ISSUE_TYPES } from "lib/consts/issueTypes";
import styled from "styled-components";
import { getApiUrl } from "lib/helpers/getApiUrl";

const StyledText =  styled.div`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`

export const Jira = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    const values = getFormValues(formRef.current);
    await fetchData(`${getApiUrl("ADMIN_API")}/customers/${data.id}/connectors/jira`, JSON.stringify({ ...values }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };
  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section>
          <Input name="projectKey" label="Project Key" defaultValue={data.connectors.jira.projectKey} readOnly={!isEditMode} placeholder="i.e DF, QUOR etc" description={isEditMode && "This value should be letter characters and not contain only numbers i.e QUOR"} />
          <Input name="projectName" label="Project Name" defaultValue={data.connectors.jira.projectName} readOnly={!isEditMode} placeholder="i.e Defense Fun, Quorum Cyber etc"/>
          <Input name="projectId" label="Project Id" defaultValue={data.connectors.jira.projectId} readOnly={!isEditMode} placeholder="i.e 12345" description={isEditMode && "This value should be numbers and not contain letters i.e 12345"}/>
          <Input label="Incident Issue Type">
            <Select
              name="issueType"
              label="Incident Issue Type"
              style={{ margin: "0.3rem 0" }}
              defaultValue={data.connectors.jira.issueType || ISSUE_TYPES["service request"]}
              readOnly={!isEditMode}
            >
              <option value="">Select Type</option>
              <option value={ISSUE_TYPES["service request"]}>Service Request (Old Schema)</option>
              <option value={ISSUE_TYPES["cyber incident"]}>Cyber Incident (New Schema)</option>
            </Select>
          </Input>
        </section>
      </StyledForm>
      {isEditMode && (
        <>
        <StyledText>
          <strong>Important:</strong> Any updates made to existing values may take up to an hour to take effect as a cache is in place. The cache refreshes on the hour and cannot be manually cleared. 
        </StyledText>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
        </>
      )}
    </div>
  );
};
