import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import { faPaste, faExternalLinkAlt, faPlus, faDirections } from "@fortawesome/free-solid-svg-icons";
import { getArtifactContent } from "lib/helpers/getArtifactContent";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { Tooltip } from "lib/components/Tooltip";
import { copyText } from "lib/helpers/copyText";
import { getUrlPath } from "lib/helpers/getUrlPath";
import { Dropdown } from "lib/components/Dropdown";
import { Tag } from "lib/components/Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchData } from "lib/helpers/fetchData";
import { getSeverityTextFromScore } from "lib/helpers/getColorFromSeverity";
import { useHistory } from "react-router-dom";
import { Redirects } from "apps/phish/artifacts/single-artifact/components/Redirects";
import { KeyValue } from "lib/components/KeyValue";
import { getApiUrl } from "lib/helpers/getApiUrl";

const updateArtifact = async (customerId, ticketId, artifactId, severity, tags) => {
  return await fetchData(
    `${getApiUrl("PHISH_API")}/tickets/${customerId}/${ticketId}/artifacts/${artifactId}/update`,
    JSON.stringify({ severity, tags }),
    "POST"
  );
};

const StyledArtifactTable = styled.table`
  color: ${(props) => props.theme.text};
  border-spacing: 0;
  margin: -1.8rem;
  width: calc(100% + 3.6rem);
  overflow: auto;
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  th {
    padding: 0 1rem;
    text-align: left;
    color: ${(props) => props.theme.lightText};
    font-weight: normal;
    font-size: 1.25rem;
    height: 3.5rem;
  }
  td {
    min-height: 4rem;
    padding: 0 1.25rem;
    font-size: 1.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:nth-of-type(1) {
      text-align: center;
    }
    .link {
      margin: 0;
      padding: 0.8rem 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  th,
  td {
    &:nth-of-type(2) {
      padding-left: 0;
    }
    &:nth-of-type(5),
    &:nth-of-type(6) {
      text-align: right;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: ${(props) => props.theme.accentCardBackground};
      }
      &:last-child {
        td:first-child {
          border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
          border-bottom-right-radius: 0.8rem;
        }
      }
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.text};
`;

const VALID_IMG_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "bmp"];

export const Artifacts = ({ data: ticket, type, refresh, tagsState = {} }) => {
  const [artifacts, setArtifacts] = useState([]);
  const history = useHistory();
  const { data: allTags } = tagsState;

  useEffect(() => {
    let _artifacts = ticket.mail.children.filter((a) => a.type === type);
    const unseen = _artifacts.filter((a) => a.tags.every((t) => t.name.toLowerCase() !== "recurring benignity"));
    const common = _artifacts.filter((a) => a.tags.some((t) => t.name.toLowerCase() === "recurring benignity"));
    _artifacts = [...unseen, ...common];
    setArtifacts(_artifacts);
  }, [ticket, type]);

  const getImageSource = (artifact) => {
    if (artifact.properties?.contentType?.toString()?.split("/")?.[0] === "image") {
      return artifact.properties?.location?.url;
    }
    if (artifact.properties?.url) {
      const url = artifact.properties.url.toLowerCase();
      let ext = url.substr(url.lastIndexOf(".") + 1);
      if (VALID_IMG_EXTENSIONS.includes(ext)) {
        return artifact.properties.url;
      }
    }
    return null;
  };

  const handleAddTag = (artifact, index, tag) => {
    const newTags = [...(artifact.tags || []), JSON.parse(tag)];
    updateArtifact(ticket.customer.id, ticket.id, artifact.id, artifact.severity, newTags).then(() => refresh());
    let newArtifacts = [...artifacts];
    newArtifacts[index] = { ...artifacts[index], tags: newTags };
    setArtifacts(newArtifacts);
  };

  const handleDeleteTag = (artifact, index, tagId) => {
    const newTags = artifact.tags.filter((tag) => tag.id !== tagId);
    updateArtifact(ticket.customer.id, ticket.id, artifact.id, artifact.severity, newTags).then(() => refresh());
    let newArtifacts = [...artifacts];
    newArtifacts[index] = { ...artifacts[index], tags: newTags };
    setArtifacts(newArtifacts);
  };

  const handleEvaluation = (artifact, index, _score) => {
    const score = parseInt(_score);
    updateArtifact(ticket.customer.id, ticket.id, artifact.id, { score }, artifact.tags).then(() => refresh());
    let newArtifacts = [...artifacts];
    newArtifacts[index] = {
      ...artifacts[index],
      severity: { score, text: getSeverityTextFromScore(score) },
      confidence: { text: "Confirmed" },
    };
    setArtifacts(newArtifacts);
  };

  return (
    <StyledArtifactTable style={{ tableLayout: artifacts.length ? "fixed" : "auto" }}>
      <thead>
        <tr>
          <th style={{ width: "5rem" }} />
          <th style={{ width: "7rem" }}>Preview</th>
          <th style={{ width: "3rem" }} />
          <th style={{ width: "40vw", textTransform: "capitalize" }}>{`${type}s`}</th>
          <th>Tags</th>
          <th style={{ width: "10rem" }}>First Seen</th>
          <th style={{ width: "18rem" }}>Severity</th>
        </tr>
      </thead>
      <tbody>
        {artifacts.length > 0 ? (
          artifacts.map((artifact, i) => {
            const imageSource = getImageSource(artifact);
            const isRecBen = artifact.tags.some((t) => t.name.toLowerCase() === "recurring benignity");
            const redirectsLength = artifact?.properties?.redirects?.length;
            return (
              <tr key={artifact.id}>
                <td style={{ cursor: "default", overflow: "unset" }}>
                  <Tooltip
                    icon={faPaste}
                    xHoverPos="right"
                    clickText="Copied!"
                    clickAction={() => copyText(artifact.id)}
                    style={{ marginTop: "6px" }}
                  />
                  <textarea
                    id={artifact.id}
                    defaultValue={getArtifactContent(artifact)}
                    style={{ position: "absolute", left: "-9999px" }}
                  />
                  {type === "url" && (
                    <a href={getUrlPath(artifact)} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "0.5rem" }}>
                      <Tooltip xHoverPos="right" yHoverPos="center" icon={faExternalLinkAlt} hoverText="Open in new tab" />
                    </a>
                  )}
                </td>
                <td style={{ maxWidth: "50px", maxHeight: "80%" }}>
                  {imageSource && (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img src={imageSource} style={{ maxHeight: "3rem", maxWidth: "100%" }} alt="artifact preview" />
                    </div>
                  )}
                </td>
                <td>
                  {redirectsLength ? (
                    <Tooltip
                      icon={faDirections}
                      xHoverPos="right"
                      yHoverPos="top"
                      spacing={1.5}
                      style={{ padding: "0.8rem 2rem 0.8rem 0" }}
                      hoverText={
                        <KeyValue
                          text={`Redirects (${redirectsLength})`}
                          wrap={false}
                          style={{ padding: "0.4rem 0.2rem" }}
                          value={<Redirects data={artifact} />}
                        />
                      }
                    />
                  ) : null}
                </td>
                <td style={{ opacity: isRecBen ? 0.4 : 1 }}>
                  <p
                    className="link"
                    onClick={() => history.push(`/phish/tickets/${ticket.customer.id}/${ticket.id}/artifacts/${artifact.id}`)}
                  >
                    {getArtifactContent(artifact)}
                  </p>
                </td>
                <td
                  style={{
                    opacity: isRecBen ? 0.4 : 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-end" }}>
                    {artifact.tags.map((tag) => (
                      <Tag key={tag.id} name={tag.name} callback={() => handleDeleteTag(artifact, i, tag.id)} />
                    ))}
                  </div>
                  <Dropdown
                    secondary
                    showDrop={false}
                    style={{ width: "2.35rem", height: "2.35rem", minWidth: "2.35rem" }}
                    label={<StyledIcon icon={faPlus} />}
                    callback={(e) => handleAddTag(artifact, i, e)}
                  >
                    {allTags?.result
                      ?.filter((tag) => !artifact.tags.map((tag) => tag.id).includes(tag.id))
                      ?.map(({ id, name }) => (
                        <option key={id} value={JSON.stringify({ id, name })}>
                          {name}
                        </option>
                      ))}
                  </Dropdown>
                </td>
                <td style={{ opacity: isRecBen ? 0.4 : 1 }}>{`${moment(artifact.firstSeen).fromNow()}`}</td>
                <td style={{ opacity: isRecBen ? 0.4 : 1 }}>
                  <Dropdown
                    secondary
                    style={{ width: "17rem", color: getColorFromSeverity(artifact.severity) }}
                    label={`${artifact.severity.text} ${artifact.severity.score > 0 ? `(${artifact?.confidence?.text})` : ""}`}
                    callback={(e) => handleEvaluation(artifact, i, e)}
                  >
                    <option value="1">Benign</option>
                    <option value="2">Suspicious</option>
                    <option value="3">Malicious</option>
                  </Dropdown>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className="no-results-row">
            <td colSpan="70" className="no-results-text">
              <p>No results</p>
            </td>
          </tr>
        )}
      </tbody>
    </StyledArtifactTable>
  );
};
