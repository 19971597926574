import React, { useState } from "react";

import { CTAButton } from "lib/components/CTAButton";

import { fetchData } from "lib/helpers/fetchData";
import { ConfirmModal } from "lib/components/Modal";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const DeleteTemplate = ({ template, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });

  const handleDelete = () => {
    const customerId = template.customer.id;
    const templateId = template.id;
    setIsLoading(true);
    setError(null);
    fetchData(`${getApiUrl("REPORTING_API")}/phish-sim/manage/templates/delete/${customerId}/${templateId}`, null, "DELETE")
      .then(() => {
        setIsLoading(false);
        refresh();
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e?.error?.message || e?.error || e);
      });
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(255, 0, 0, 0.6)",
        borderRadius: "0.8rem",
        padding: "1rem",
        marginTop: "1rem"
      }}
    >
      <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this template permanently</p>
      <CTAButton onClick={handleDeletePrompt} style={{ borderColor: "rgba(255, 0, 0, 0.3)" }} isSecondary isLoading={isLoading} error={error}>
        Delete
      </CTAButton>
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </div>
  );
};