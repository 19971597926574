import { useEffect, useState } from "react";
import styled from "styled-components";
import { Spinner } from "lib/helpers/fetchData";

import { Navbar } from "lib/components/Navbar";
import { FullWidthContainer } from "lib/components/FullWidthContainer";
import { ScrollContainer } from "lib/components/ScrollContainer";

import { AttachmentsList } from "../attachments/AttachmentsList";
import { KBIssuesList } from "./KBIssuesList";
import { SingleKBIssue } from "./SingleKBIssue";
import { CTAButton } from "lib/components/CTAButton";
import { useQuery } from "lib/helpers/useQuery";
import { useDataList } from "lib/helpers/useDataList";
import { CreateKBIssue } from "./CreateKBIssue";
import { useHistory } from "react-router-dom";
import { getApiUrl } from "lib/helpers/getApiUrl";

const defaultPath = { text: "KB Issues", link: "#" };

export const KBIssues = () => {
  const history = useHistory();
  const { kbIssueId, isCreateKBIssue } = useQuery();
  const [attachments, setAttachments] = useState([]);
  const [attachmentsPreference, setAttachmentsPreference] = useState(null);
  const [showAttachments, setShowAttachments] = useState(null);
  const [paths, setPaths] = useState([defaultPath]);

  const url = `${getApiUrl("PENTEST_API")}/kbissues`;
  const kbIssuesData = useDataList({ url, orderBy: "createdAt", reverse: true });

  const kbIssues = kbIssuesData?.data?.result || [];
  const kbIssue = kbIssues?.find((issue) => issue.id === kbIssueId) || {};

  useEffect(() => {
    let newPaths = [defaultPath];
    if (kbIssue.title && kbIssueId) {
      newPaths.push({ text: kbIssue.title, link: `/pentest/kb-issues?kbIssueId=${kbIssueId}` });
    }
    setPaths(newPaths);
  }, [kbIssue.title, kbIssueId]);

  useEffect(() => {
    const kbIssues = kbIssuesData?.data?.result || [];
    if (!kbIssueId && !isCreateKBIssue && kbIssues.length) {
      const firstIssue = kbIssues[0];
      history.push(`/pentest/kb-issues?kbIssueId=${firstIssue.id}`);
    }
  }, [kbIssueId, isCreateKBIssue, kbIssuesData, history]);

  useEffect(() => {
    setShowAttachments(!kbIssueId);
  }, [kbIssueId]);

  const showAttachmentsPanel = attachmentsPreference === null ? showAttachments : attachmentsPreference;

  return (
    <FullWidthContainer style={{ paddingBottom: 0 }}>
      <Navbar
        paths={paths}
        action={
          !isCreateKBIssue && (
            <CTAButton isSecondary style={{ width: "15rem" }} onClick={() => setAttachmentsPreference(!showAttachmentsPanel)}>
              {showAttachmentsPanel ? "Hide" : "Show"} attachments
            </CTAButton>
          )
        }
      />
      <StyledContainer id="scrolling-element" scrollX="auto" scrollY="unset">
        <div className="side-list left">
          <KBIssuesList kbIssuesData={kbIssuesData} />
        </div>
        <div className="main-body" style={{ minWidth: !showAttachmentsPanel && "calc(100% - 44.5rem)" }}>
          {isCreateKBIssue ? (
            <CreateKBIssue reload={kbIssuesData.reload} />
          ) : kbIssueId ? (
            <SingleKBIssue key={kbIssueId} kbIssue={kbIssue} kbIssueId={kbIssueId} attachments={attachments} reload={kbIssuesData.reload} />
          ) : (
            <Spinner />
          )}
        </div>
        {!isCreateKBIssue && (
          <div className="side-list right">
            <AttachmentsList targetId={kbIssueId} setAttachments={setAttachments} />
          </div>
        )}
      </StyledContainer>
    </FullWidthContainer>
  );
};

const StyledContainer = styled(ScrollContainer)`
  display: flex;
  flex: 1;
  & > .side-list {
    min-width: 40rem;
    max-width: 40rem;
    margin-top: 1px;
    padding-bottom: 1.5rem;
    width: 40rem;
    display: flex;
    flex-flow: column nowrap;
    &.left {
      padding-right: 1.5rem;
      border-right: 1px solid ${(props) => props.theme.headerOutline};
      margin-right: 1.5rem;
    }
    &.right {
      padding-left: 1.5rem;
      border-left: 1px solid ${(props) => props.theme.headerOutline};
      margin-left: 1.5rem;
    }
  }
  & > .main-body {
    flex: 1;
    margin-top: 0.7rem;
    display: flex;
    padding-bottom: 3rem;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s;
    & > div:not(.spinner) {
      width: 100%;
      box-sizing: border-box;
    }
    & > .spinner {
      flex: 1;
    }
  }
`;
