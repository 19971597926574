import React, { useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { fetchData } from "lib/helpers/fetchData";
import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { TextArea } from "lib/components/TextArea";
import { CTAButton } from "lib/components/CTAButton";
import { ErrorsList } from "lib/components/ErrorsList";

import { Events } from "./components/Events";
import { Targets } from "./components/Targets";
import { Templates } from "./components/Templates";
import { CustomerPicker } from "./components/CustomerPicker";
import { DateTimeWindows } from "./components/DateTimeWindows";
import { DeleteCampaign } from "./components/DeleteCampaign";
import { CheckboxButton } from "lib/components/Checkbox";
import { UploadGoPhish } from "./components/UploadGoPhish";
import { getApiUrl } from "lib/helpers/getApiUrl";

const StyledCampaignForm = styled(StyledForm)`
  width: 100%;
  max-width: 100rem;
  margin: auto;
  position: relative;

  & > section {
    width: 80rem;
    max-width: 100%;
    margin: auto;
  }
`;

export const CampaignForm = ({ campaign = {}, isEditMode = true, setIsEditMode, refresh }) => {
  const [customer, setCustomer] = useState(campaign.customer?.id);
  const [template, setTemplate] = useState(campaign.template || {});
  const [targets, setTargets] = useState(campaign.targets || []);
  const [events, setEvents] = useState(campaign.events || []);
  const [hideFields, setHideFields] = useState(!!campaign.isBlank);
  const [errors, setErrors] = useState([]);

  const history = useHistory();
  const formRef = useRef(null);

  const setIsBlank = (e) => setHideFields(e.target.checked);

  const handleSubmit = async () => {
    let values = getFormValues(formRef.current);

    console.log(values);

    let errors = [];

    values.customer = customer;
    values.template = template?.id;
    values.targets = targets;
    values.events = events;

    if (hideFields) {
      delete values.targets;
      delete values.events;
      delete values.opened;
      delete values.clicked;
      delete values.submitted;
      delete values.reported;
      delete values.template;
      delete values.overview;
      delete values.analysis;
      delete values.summary;
      delete values.recommendations;
    } else {
      delete values.isBlank;
    }

    Object.entries(values).forEach(([key, value]) => {
      if (!value) errors.push(key);
    });

    if (!hideFields && !values.targets.length) errors.push("Must have at least one target");

    values.startDate = moment(`${values.startDate} ${values.startTime}`).toISOString();
    values.endDate = moment(`${values.endDate} ${values.endTime}`).toISOString();

    if (values.sendingStartDate) {
      values.sendingStartDate = moment(`${values.sendingStartDate} ${values.sendingStartTime}`).toISOString();
      values.sendingEndDate = moment(`${values.sendingEndDate} ${values.sendingEndTime}`).toISOString();
    }

    values.isBlank = hideFields;

    if (campaign.id && campaign.fid) {
      values.id = campaign.id;
      values.fid = campaign.fid;
    }

    setErrors(errors);
    if (errors.length) return;

    fetchData(`${getApiUrl("REPORTING_API")}/phish-sim/manage/reports/upload`, JSON.stringify(values), "POST")
      .then((d) => refresh(d))
      .catch((e) => {
        console.log(e?.error?.message || e?.error || e)
        setErrors([e?.error?.message || e?.error || e]);
      });
  };

  return (
    <StyledCampaignForm ref={formRef}>
      {!isEditMode && setIsEditMode && (
        <CTAButton style={{ position: "absolute", right: "10%", top: "2.25rem", zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <section>
        <CustomerPicker defaultValue={campaign.customer} readOnly={!isEditMode} setCustomer={setCustomer} />
        <Input name="title" label="Campaign Title" defaultValue={campaign.title} readOnly={!isEditMode} />
        <CheckboxButton subtle name="isBlank" label="Blank campaign (i.e. not run)" readOnly={!isEditMode} defaultChecked={campaign.isBlank} showWhenNull={false} smallWhenNull onChange={setIsBlank} />
      </section>
      <section>
        <DateTimeWindows defaultValue={campaign} readOnly={!isEditMode} ref={formRef} hideFields={hideFields} />
      </section>
      {!hideFields ? (
        <>
          <section>{!campaign.id && <UploadGoPhish setTargets={setTargets} setEvents={setEvents} />}</section>
          <section>
            <Targets targets={targets} setTargets={setTargets} readOnly={!isEditMode} key={events.length} /> {/* key is hack to update on events file upload */}
          </section>
          <section>
            <Events events={events} setEvents={setEvents} eventTotals={campaign.eventTotals} targets={targets} readOnly={!isEditMode} />
          </section>
          <section style={isEditMode ? { width: "100%" } : {}}>
            <Templates template={template} setTemplate={setTemplate} customer={customer} readOnly={!isEditMode} />
          </section>
          <section>
            <Input label="Overview">
              <TextArea name="overview" rows="10" defaultValue={campaign.overview} readOnly={!isEditMode} />
            </Input>
            <Input label="Analysis">
              <TextArea name="analysis" rows="10" defaultValue={campaign.analysis} readOnly={!isEditMode} />
            </Input>
            <Input label="Summary">
              <TextArea name="summary" rows="10" defaultValue={campaign.summary} readOnly={!isEditMode} />
            </Input>
            <Input label="Recommendations">
              <TextArea name="recommendations" rows="10" defaultValue={campaign.recommendations} readOnly={!isEditMode} />
            </Input>
          </section>
        </>
      ) : (
        <section>
          <Input label="Reasoning">
            <TextArea name="reasoning" rows="10" defaultValue={campaign.reasoning} readOnly={!isEditMode} />
          </Input>
        </section>
      )}
      <section>
        <ErrorsList errors={errors} />
        {!isEditMode && <DeleteCampaign campaign={campaign} />}
        {isEditMode && (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "2rem 0 0" }}>
            <CTAButton isSecondary onClick={() => (setIsEditMode ? setIsEditMode(false) : history.push("/phish-sim/campaigns"))}>
              Cancel
            </CTAButton>
            <CTAButton onClick={handleSubmit}>{setIsEditMode ? "Update" : "Create"}</CTAButton>
          </div>
        )}
      </section>
    </StyledCampaignForm>
  );
};
