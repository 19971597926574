import { useContext } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import { useFetch, fetchData } from "lib/helpers/fetchData";
import { Modal } from "lib/components/Modal";
import { ThemeContext } from "lib/components/ThemeProvider";

import { VALUE_COLORS } from "../components/constants";
import { Header } from "./Header";
import { Evaluate } from "./Evaluate";
import { Statuses } from "./Statuses";
import { calculteWorstPriority } from "../components/helpers";
import { Details } from "./details/Details";
import { getApiUrl } from "lib/helpers/getApiUrl";

const loadProject = ({ projectId }) => {
  return fetchData(`${getApiUrl("SERVICE_DELIVERY_API")}/projects/${projectId}`);
};

const StyledContainer = styled.div`
  border-radius: 0.5rem;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: ${(props) =>
    `inset 0 30rem 7rem -28rem ${props.color}, inset 0 25rem 8rem -22rem ${props.color}, 0 2rem 10rem 1.5rem rgba(0, 0, 0, 0.8), 0 2rem 15rem 4rem rgba(0, 0, 0, 0.5)`};
  overflow: hidden;
  cursor: default;
  transition: all 0.3s;
  width: 100%;
  padding: 1.25rem 0.75rem 0.75rem;

  & > .summary {
    display: flex;
    flex-flow: column nowrap;
    & > .statuses {
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;
    background: ${(props) => props.theme.cardBackground};
    overflow: hidden;
    z-index: -1;
  }
`;

export const SingleProject = ({ getClient, getProjectManager, metrics, refresh }) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const { projectId } = useParams();

  const { data, isLoading, error, reload } = useFetch(loadProject, { projectId });

  const project = data?.result || {};

  const client = getClient(project.client?.id) || {};
  const projectManager = getProjectManager(project.projectManager?.id) || {};

  const worstPriority = calculteWorstPriority({ project, metrics });

  const refreshAll = () => {
    reload();
    refresh();
  }

  return (
    <Modal subtle width="90rem" hide={history.goBack} isModalLoading={isLoading} modalError={error}>
      <StyledContainer color={VALUE_COLORS[worstPriority] || theme.cardBackground}>
        <Header project={project} projectManager={projectManager} client={client} />
        <Evaluate project={project} refresh={refreshAll} />
        <Statuses project={project} />
        <Details project={project} />
      </StyledContainer>
    </Modal>
  );
};
