import { Modal } from "lib/components/Modal";
import { getApiUrl } from "lib/helpers/getApiUrl";
const { ReviewBox } = require("apps/pentest/reviews");
const { InfiniteScroll } = require("lib/components/InfiniteScroll");
const { LinkRow } = require("lib/components/LinkRow");
const { ScrollContainer } = require("lib/components/ScrollContainer");
const { Searchbar } = require("lib/components/Searchbar");
const { SortableHeader } = require("lib/components/SortableHeader");
const { Table } = require("lib/components/Table");
const { useDataList } = require("lib/helpers/useDataList");
const { useDebouncedCallback } = require("use-debounce/lib");

const url = `${getApiUrl("PENTEST_API")}/kbissues`;

export const KBIssueSelection = ({ selectKBIssue, hide }) => {
  const kbIssuesData = useDataList({ url, orderBy: "createdAt", reverse: true });
  const { params, setParams, ...kbIssues } = kbIssuesData;

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => setParams((p) => ({ ...p, ...sort })),
  };

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const handleSelect = (issue) => {
    selectKBIssue(issue);
    hide();
  }

  return (
    <Modal hide={hide} style={{paddingBottom:"1rem"}}>
      <Searchbar {...search} placeholder="Search KB Issues" small />
      <ScrollContainer className="scroll">
          <InfiniteScroll {...kbIssues} useWindow={false} noDataText="No KB issues added yet">
            <Table tableStyle={{ tableLayout: "fixed" }} small>
              <thead>
                <tr>
                  <th style={{ width: "8rem" }}></th>
                  <SortableHeader field="title" {...sort} headerStyle={{ paddingLeft: "0.3rem" }}>
                    Issue Title
                  </SortableHeader>
                  <SortableHeader field="severity" {...sort} style={{ justifyContent: "flex-end" }} headerStyle={{ width: "3.5rem" }}>
                    Severity
                  </SortableHeader>
                  <SortableHeader field="cvssScore" {...sort} style={{ justifyContent: "flex-end" }} headerStyle={{ width: "2.5rem" }}>
                    CVSS
                  </SortableHeader>
                </tr>
              </thead>
              <tbody>
                {kbIssues.data.result.map((issue) => (
                  <LinkRow key={issue.id} onClick={() => handleSelect(issue)}>
                    <td style={{ padding: 0, paddingLeft: "0.2rem" }}>{<ReviewBox status={issue.status} />}</td>
                    <td style={{ padding: 0, paddingLeft: "0.2rem" }}>{issue.title}</td>
                    <td style={{ paddingLeft: 0, textAlign: "right" }}>{issue.severity}</td>
                    <td style={{ paddingLeft: 0, textAlign: "right" }}>{issue.cvssScore}</td>
                  </LinkRow>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </ScrollContainer>
    </Modal>
  )
}