import React, { useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import { Modal } from "lib/components/Modal";
import { fetchData } from "lib/helpers/fetchData";
import { getFormValues } from "lib/helpers/getFormValues";
import { ReleaseForm } from "./ReleaseForm";
import { getApiUrl } from "lib/helpers/getApiUrl";

const StyledModal = styled(Modal)`
  && {
    width: 80rem;
  }
`;

export const CreateRelease = ({ show, hide, refresh }) => {
  const [errors, setErrors] = useState([]);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    let release = getFormValues(formRef.current);
    let errors = [];

    if (!release.title) errors.push("Missing title");
    if (!release.version) errors.push("Missing version");
    if (!release.date) errors.push("Missing date");
    if (!moment(release.date).isValid()) errors.push("Invalid date");

    setErrors(errors);
    if (errors.length) return;

    release.date = moment(release.date).toISOString();

    fetchData(`${getApiUrl("ADMIN_API")}/releases`, JSON.stringify(release), "POST")
      .then((d) => refresh(d))
      .catch((e) => {
        console.log(e?.error?.message || e?.error || e);
        setErrors([e?.error?.message || e?.error || e]);
      });
  };

  return (
    <StyledModal showModal={show} hide={hide} title="Create Release">
      <ReleaseForm hide={hide} handleSubmit={handleSubmit} isEditMode errors={errors} ref={formRef} />
    </StyledModal>
  );
};
