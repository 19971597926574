import React, { useState, useRef } from "react";
import { fetchData } from "lib/helpers/fetchData";

import { StyledContentContainer } from "lib/components/ListPane";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { useIsFeatureEnabled, flags } from "lib/feature-flagging/treatments";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const upsertCustomer = async ({ name, code, customerId, usingIdem }) => {
  const method = customerId ? "PUT" : "POST";
  let apiUrl, path;

  if (usingIdem) {
    apiUrl = getApiUrl("IDEM_API");
    path = `/organisations/${customerId || ""}`;
  } else {
    apiUrl = getApiUrl("ADMIN_API");
    path = `/customers/${customerId || ""}`;
  }

  const apiPath = `${apiUrl}${path}`;

  return fetchData(apiPath, JSON.stringify({ name, code }), method);
};

export const UpsertCustomer = ({ reload, data: customer, endCreation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const usingIdem = useIsFeatureEnabled(flags.MANAGE_ORGANISATIONS_WITH_IDEM);

  const handleUpsertCustomer = async () => {
    const { name, code } = getFormValues(formRef.current);
    const customerId = customer?.id;
    setIsLoading(true);
    const newCustomer = await upsertCustomer({ name, code, customerId, usingIdem });
    setIsLoading(false);
    if (usingIdem) endCreation();
    else endCreation(newCustomer.result.id);
    reload();
  };

  const handleCancel = () => endCreation(customer?.id);

  const verb = !customer ? "Create" : "Edit";

  return (
    <StyledContentContainer>
      <div className="body small">
        <StyledForm ref={formRef}>
          <h1>{verb} Customer</h1>
          <Input name="name" label="Name" defaultValue={customer?.name} />
          <Input name="code" label="Code" defaultValue={customer?.code} />
        </StyledForm>
        {usingIdem && (
          <h2>
            Please note: After adding or updating a customer, it may take a few minutes for the changes to reflect in the user interface.
            You may need to refresh the page to see the updated customer information in the left pane.
          </h2>
        )}
      </div>
      <div className="footer">
        <div style={{ display: "flex", justifyContent: "flex-end", flex: 1, gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleCancel}>
            Cancel
          </CTAButton>
          <CTAButton onClick={handleUpsertCustomer} isLoading={isLoading}>
            {verb}
          </CTAButton>
        </div>
      </div>
    </StyledContentContainer>
  );
};
