import React, { createContext, useState, useContext, useEffect } from "react";

import { fetchData } from "lib/helpers/fetchData";

const RegionContext = createContext();

export const RegionProvider = ({ children }) => {
  const [regionUrl, setRegionUrl] = useState(() => {
    const storedRegionUrl = localStorage.getItem("regionBaseUrl");

    return storedRegionUrl || null;
  });

  const [allRegions, setAllRegions] = useState([]);

  useEffect(() => {
    const fetchRegions = async () => {
      const data = await fetchData(`${process.env.REACT_APP_API_GATEWAY}/organisations`);

      const uniqueRegions = {};
      data.forEach((item) => {
        const { name, apiUrl } = item.metaData;
        if (!uniqueRegions[name]) {
          uniqueRegions[name] = apiUrl;
        }
      });

      const regionsArray = Object.entries(uniqueRegions).map(([name, url]) => ({
        name,
        url,
      }));

      setAllRegions(regionsArray);

      if (!regionUrl) {
        changeRegion(regionsArray[0].url);
      }
    };

    fetchRegions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRegion = (newRegion) => {
    setRegionUrl(newRegion);
    localStorage.setItem("regionBaseUrl", newRegion);
  };

  return (
    <RegionContext.Provider key={regionUrl} value={{ regionUrl, changeRegion, setAllRegions, allRegions }}>
      {children}
    </RegionContext.Provider>
  );
};

export const useRegion = () => {
  const context = useContext(RegionContext);

  if (context === undefined) {
    throw new Error("useRegion must be used within a RegionProvider");
  }

  return context;
};
