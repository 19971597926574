import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { CTAButton } from "lib/components/CTAButton";
import { CheckboxButton } from "lib/components/Checkbox";
import { KeyValue } from "lib/components/KeyValue";
import { fetchData } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const Vuln = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    let { enabled, ctem } = getFormValues(formRef.current);
    await fetchData(`${getApiUrl("ADMIN_API")}/customers/${data.id}/apps/vuln`, JSON.stringify({ enabled, ctem }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section style={{ paddingTop: 0 }}>
          <KeyValue
            text="Service Status"
            wrap={false}
            value={
              <CheckboxButton
                name="enabled"
                subtle
                showWhenNull
                label={!isEditMode ? (data?.apps?.vuln?.enabled ? "Enabled" : "Disabled") : "Is Enabled?"}
                defaultChecked={!!data?.apps?.vuln?.enabled}
                readOnly={!isEditMode}
              />
            }
          />
        </section>
        <section style={{ paddingTop: 0 }}>
        <KeyValue
              text="CTEM"
              wrap={false}
              value={
                <CheckboxButton
                  name="ctem"
                  subtle
                  showWhenNull
                  label={!isEditMode ? (data?.apps?.vuln?.ctem ? "Enabled" : "Disabled") : "Is Enabled?"}
                  defaultChecked={!!data?.apps?.vuln?.ctem}
                  readOnly={!isEditMode}
                />
              }
            />
            </section>
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
    </div>
  );
};
