import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { CTAButton } from "lib/components/CTAButton";
import { CheckboxButton } from "lib/components/Checkbox";
import { KeyValue } from "lib/components/KeyValue";
import { fetchData } from "lib/helpers/fetchData";
import { Overrides } from "./Overrides";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const Soc = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    let { enabled, mxdr, ingestion, mispTI, resourceOrchestrator, onboarding } = getFormValues(formRef.current);

    // Check if the onboarding status has changed
    let onboardingLastModified = data?.apps?.soc?.onboardingLastModified;
    if (onboarding !== data?.apps?.soc?.onboarding) {
      onboardingLastModified = new Date().toISOString();
    }
    
    await fetchData(
      `${getApiUrl("ADMIN_API")}/customers/${data.id}/apps/soc`,
      JSON.stringify({ enabled, mxdr, ingestion, mispTI, resourceOrchestrator, onboarding, onboardingLastModified }),
      "PUT"
    );
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleExit = () => setIsEditMode(false);

  return (
    <>
      <div style={{ position: "relative" }}>
        {!isEditMode && (
          <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
            Edit
          </CTAButton>
        )}
        <StyledForm ref={formRef}>
          <section style={{ paddingTop: 0 }}>
            <KeyValue
              text="Service Status"
              wrap={false}
              value={
                <CheckboxButton
                  name="enabled"
                  subtle
                  showWhenNull
                  label={!isEditMode ? (data?.apps?.soc?.enabled ? "Enabled" : "Disabled") : "Is Enabled?"}
                  defaultChecked={!!data?.apps?.soc?.enabled}
                  readOnly={!isEditMode}
                />
              }
            />
            <KeyValue
              text="Resource Orchestrator"
              wrap={false}
              value={
                <CheckboxButton
                  name="resourceOrchestrator"
                  subtle
                  showWhenNull
                  label={!isEditMode ? (data?.apps?.soc?.resourceOrchestrator ? "Enabled" : "Disabled") : "Is Enabled?"}
                  defaultChecked={!!data?.apps?.soc?.resourceOrchestrator}
                  readOnly={!isEditMode}
                />
              }
            />
            <KeyValue
              text="MXDR"
              wrap={false}
              value={
                <CheckboxButton
                  name="mxdr"
                  subtle
                  showWhenNull
                  label={!isEditMode ? (data?.apps?.soc?.mxdr ? "Enabled" : "Disabled") : "Is Enabled?"}
                  defaultChecked={!!data?.apps?.soc?.mxdr}
                  readOnly={!isEditMode}
                />
              }
            />
          </section>
          <section>
          <Input label="Onboarding Status">
                <Select
                  name="onboarding"
                  label="Onboarding Status"
                  style={{ margin: "0.3rem 0" }}
                  defaultValue={
                    ['Onboarding', 'Onboarded', 'Offboarded'].includes(data?.apps?.soc?.onboarding) 
                    ? data?.apps?.soc?.onboarding 
                    : ''
                }
                  readOnly={!isEditMode}
                >
                  <option value="">Select Status</option>
                  <option value="Onboarding">⏳ Onboarding </option>
                  <option value="Onboarded">✅ Onboarded </option>
                  <option value="Offboarded">❌ Offboarded</option>
                </Select>
              </Input>
              <KeyValue
              text="Last Modified"
              wrap={false}
              value={data?.apps?.soc?.onboardingLastModified ? new Date(data?.apps?.soc?.onboardingLastModified).toLocaleDateString() : "N/A"}
              />
          </section>
          <section>
            <Input label="Ingestion Type">
              <Select
                name="ingestion"
                label="Ingestion Type"
                style={{ margin: "0.3rem 0" }}
                defaultValue={data?.apps?.soc?.ingestion || "legacy"}
                readOnly={!isEditMode}
              >
                <option value="">Select Type</option>
                <option value="legacy">Service Onboarding/Legacy Case Logging</option>
                <option value="ecl">Enhanced Case Logging</option>
                <option value="integrations">Clarity Integrations Engine</option>
                <option value="internal-analytics">Internal / Analytics Ingestion</option>
              </Select>
            </Input>
            <Input label="Send TI from MISP" labelStyle={{ marginTop: "2rem" }}>
              <Select
                name="mispTI"
                label="Send TI from MISP"
                style={{ margin: "0.3rem 0" }}
                defaultValue={data?.apps?.soc?.mispTI || ""}
                readOnly={!isEditMode}
              >
                <option value="">Disabled</option>
                <option value="baseline">Send baseline TI</option>
                <option value=""></option>
              </Select>
            </Input>
          </section>
          <section></section>
        </StyledForm>
        {isEditMode && (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
            <CTAButton isSecondary onClick={handleExit}>
              Cancel
            </CTAButton>
            <CTAButton isLoading={isLoading} onClick={handleSubmit}>
              Save
            </CTAButton>
          </div>
        )}
      </div>
      <Overrides customerId={data.id} />
    </>
  );
};
