import { useRef, useState } from "react";
import { Card } from "lib/components/Card";
import { Select } from "lib/components/Select";
import { fetchData, useFetch, Error } from "lib/helpers/fetchData";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { addParamsToUrl } from "lib/helpers/addParamsToUrl";
import { getFormValues } from "lib/helpers/getFormValues";
import moment from "moment";
import { CheckboxButton } from "lib/components/Checkbox";
import { getApiUrl } from "lib/helpers/getApiUrl";

const loadCustomers = () => fetchData(`${getApiUrl("ADMIN_API")}/customers`);

const downloadExport = async ({ customerId, startDate, endDate, includeComments, includeChangelogs }) => {
  const url = new URL(`${getApiUrl("SOC_API")}/export`);
  addParamsToUrl(url, { customerId, startDate, endDate, includeComments, includeChangelogs });
  const data = await fetchData(url.href);

  const element = document.createElement("a");
  element.setAttribute("href", `data:application/json;charset=utf-8,` + encodeURIComponent(JSON.stringify(data.result)));
  element.setAttribute("download", `soc-export-${moment().format("YYYY-MM-DD")}.json`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const ExportData = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const { data: customers } = useFetch(loadCustomers);

  const handleExport = async () => {
    const { startDate, endDate, includeComments, includeChangelogs, customerId } = getFormValues(formRef.current);
    setIsLoading(true);
    try {
      await downloadExport({ startDate, endDate, includeComments, includeChangelogs, customerId });
    } catch (error) {
      setError(error?.error?.message || error?.message || error);
    } finally {
      setIsLoading(false);
    }
  };

  const oneMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
  const todaysDate = moment().format("YYYY-MM-DD");

  if (error) return <Error message={error} />;

  return (
    <Card>
      <h1>Download tickets</h1>
      <form ref={formRef}>
        <CustomerPicker customers={customers} />
        <Input label="Start Date" type="date" name="startDate" defaultValue={oneMonthAgo} max={todaysDate} />
        <Input label="End Date" type="date" name="endDate" defaultValue={todaysDate} max={todaysDate} />
        <CheckboxButton label="Include comments" type="checkbox" name="includeComments" subtle defaultChecked />
        <CheckboxButton label="Include changelogs" type="checkbox" name="includeChangelogs" subtle defaultChecked />
      </form>
      <div style={{ display: "flex", gap: "1.5rem", margin: "1rem 0.3rem" }}>
        <CTAButton onClick={handleExport} isLoading={isLoading}>
          Download File
        </CTAButton>
      </div>
    </Card>
  );
};

const CustomerPicker = ({ customers }) => {
  return (
    <Input label="Customer">
      <Select name="customerId" style={{ marginTop: "0.3rem", marginBottom: "1rem", width: "100%" }}>
        {customers?.result
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          ?.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
      </Select>
    </Input>
  );
};
