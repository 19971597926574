import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { Table } from "lib/components/Table";
import { Tooltip } from "lib/components/Tooltip";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "lib/components/Modal";
import { CheckboxButton } from "lib/components/Checkbox.js";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const Qualys = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const [tags, setTags] = useState(data.connectors.qualys.qualysTags || []);

  const handleSubmit = async (newTags) => {
    setIsLoading(true);
    const values = getFormValues(formRef.current);
    values.qualysTags = newTags || tags;
    await fetchData(`${getApiUrl("ADMIN_API")}/customers/${data.id}/connectors/qualys`, JSON.stringify({ ...values }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    handleSubmit(newTags);
  };

  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section>
          <Input name="qualysId" label="Qualys Id" defaultValue={data.connectors.qualys.qualysId} readOnly={!isEditMode} />
          <Input name="qualysName" label="Qualys Name (To be deprecated)" defaultValue={data.connectors.qualys.qualysName} readOnly={!isEditMode} />
          <Input name="qualysMap" label="Qualys Map (To be deprecated)" defaultValue={data.connectors.qualys.qualysMap} readOnly={!isEditMode} />
        </section>
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={() => handleSubmit()}>
            Save
          </CTAButton>
        </div>
      )}
      <QualysTags tags={tags} setTags={handleTagsChange} />
    </div>
  );
};

export const QualysTags = ({ tags, setTags }) => {
  const [tagModal, setTagModal] = useState({ showModal: false, tag: null });

  const handleDelete = async (tagId) => {
    const newTags = tags.filter((t) => t.id !== tagId);
    setTags(newTags);
    setTagModal({ showModal: false });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Tag ID</th>
            <th>Name</th>
            <th>Shown in Clarity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tags.length ? (
            tags.map((tag) => (
              <tr key={tag.id}>
                <td>{tag.id}</td>
                <td>{tag.name}</td>
                <td>{tag.isClarityTag ? "Yes" : "No"}</td>
                <td>
                  <span style={{ position: "absolute", display: "flex", gap: "1rem", margin: "-1rem -4rem" }}>
                    <Tooltip icon={faEdit} hoverText="Edit Tag" clickAction={() => setTagModal({ tag, showModal: true })} />
                    <Tooltip icon={faTrash} hoverText="Delete Tag" clickAction={() => handleDelete(tag.id)} />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr className="no-results-row">
              <td colSpan={4} className="no-results-text">
                No tags added yet
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <CTAButton style={{ margin: "1rem 0" }} onClick={() => setTagModal({ showModal: true })}>
          Add Tag
        </CTAButton>
      </div>
      {tagModal.showModal && (
        <TagModal tags={tags} setTags={setTags} hide={() => setTagModal({ showModal: false })} defaultTag={tagModal.tag} />
      )}
    </>
  );
};

const TagModal = ({ tags, setTags, hide, defaultTag = {} }) => {
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});

  const handleAdd = async () => {
    const values = getFormValues(formRef.current);
    const { id, name, isClarityTag } = values;

    let newErrors = {};
    if (!id) newErrors.id = "No Tag ID specified";
    if (!name) newErrors.name = "No Tag Name specified";
    if (isNaN(Number(id))) newErrors.id = "Tag ID must be a number";

    if (!Object.keys(newErrors).length) {
      const newTag = { id, name, isClarityTag };

      const _tags = tags.filter((t) => t.id !== defaultTag.id);
      setTags([..._tags, newTag]);
      hide();
    }

    setErrors(newErrors);
  };

  return (
    <Modal subtitle={`${defaultTag.id ? "Edit" : "Add"} Tag`} hide={hide} actionCommand={handleAdd}>
      <StyledForm ref={formRef}>
        <Input label="Tag Id" name="id" error={errors.id} defaultValue={defaultTag.id} />
        <Input label="Name" name="name" error={errors.name} defaultValue={defaultTag.name} />
        <CheckboxButton label="Is Shown In Clarity" name="isClarityTag" defaultChecked={defaultTag.isClarityTag} />
      </StyledForm>
    </Modal>
  );
};
