import React, { useLayoutEffect, useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import { redirectToForgotPassword, redirectToLogin, redirectToSignUp } from "../auth/helpers";

import { LogoCircle } from "lib/assets/LogoCircle";
import { LogoText } from "lib/assets/LogoText";
import { EmailLogo } from "lib/assets/EmailLogo";
import { fetchData } from "lib/helpers/fetchData";
import { CTAButton } from "lib/components/CTAButton";
import { useHistory, useLocation } from "react-router";
import { useDebouncedCallback } from "use-debounce/lib";
import { getApiUrl } from "lib/helpers/getApiUrl";

const fetchSSOCheck = async ({ email }) => {
  return await fetchData(`${process.env.REACT_APP_API_GATEWAY}/sso-check?email=${email}`);
};

export const Login = () => {
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [hasSSO, setHasSSO] = useState(false);
  const emailRef = useRef(null);

  const defaultEmail = localStorage.getItem("email") || "";

  useEffect(() => {
    const { pathname, search } = location;
    if (pathname !== "/" && pathname !== "/logout") {
      localStorage.setItem("redirect", pathname + search);
      history.push("/");
    }
  }, [location, history]);

  const handleChange = useCallback(async (defaultEmail) => {
    const email = defaultEmail || emailRef.current.value;
    const ssoData = await fetchSSOCheck({ email });
    setLoading(false);
    setHasSSO(ssoData.ssoEnabled);
  }, []);

  const [changeCallback] = useDebouncedCallback(handleChange, 500);

  const handleAuth = useCallback(async () => {
    setLoading(true);
    const email = emailRef.current.value.toLowerCase();
    const emailDomain = email.split("@")[1] || "";
    redirectToLogin({ loginHint: email, domainHint: emailDomain });
  }, []);

  useLayoutEffect(() => {
    const emailEl = emailRef.current;

    const checkKey = (e) => {
      if (e.key === "Enter") {
        handleAuth();
      } else {
        changeCallback();
      }
    };

    if (defaultEmail) {
      changeCallback(defaultEmail);
    } else {
      setLoading(false);
    }

    emailEl.addEventListener("keyup", checkKey);
    return () => emailEl.removeEventListener("keyup", checkKey);
  }, [changeCallback, handleAuth, defaultEmail]);

  return (
    <StyledLoginBody>
      <StyledLoginColumn>
        <StyledLoginContent>
          <StyledLogo>
            <LogoCircle />
            <LogoText />
          </StyledLogo>
          <StyledLogin sso={hasSSO}>
            <div className="input-control">
              <EmailLogo />
              <input type="email" ref={emailRef} placeholder="Email" defaultValue={defaultEmail} />
              <input type="password" style={{ display: "none" }} />
            </div>
            <CTAButton isLoading={loading} onClick={handleAuth}>
              {hasSSO ? "Log in with SSO" : "Log in"}
            </CTAButton>
            <p className="sign-up">
              Don't have an account? <b onClick={redirectToSignUp}>Sign up now</b>
            </p>
            <p className="forgot-password" onClick={redirectToForgotPassword}>
              Forgot your password?
            </p>
          </StyledLogin>
        </StyledLoginContent>
      </StyledLoginColumn>
    </StyledLoginBody>
  );
};

const StyledLoginBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;

  * {
    font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif !important;
  }
`;

const StyledLoginColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  max-width: 450px;
  width: 100%;
  margin: 0.8rem;
`;

const StyledLoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  max-height: 100%;
  min-height: fit-content;
`;

const StyledLogin = styled.div`
  padding: 5rem;
  height: 100%;
  width: 100%;
  margin: 50px 10px -70px;
  box-sizing: border-box;

  .sign-up {
    margin: 0;
    color: ${(props) => props.theme.lightText};
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    opacity: ${(props) => (props.sso ? 0 : 1)};

    b {
      cursor: pointer;
      font-weight: normal;
      color: ${(props) => props.theme.text};
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .forgot-password {
    margin: 5px 0;
    color: ${(props) => props.theme.lightText};
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    cursor: pointer;
    opacity: ${(props) => (props.sso ? 0 : 1)};
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    outline: none;
    border: none;
    width: 100%;
    height: 36px;
    border-radius: 18px;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 7px 12px;
    color: ${(props) => props.theme.buttonText || props.theme.text};
    background-color: ${(props) => props.theme.secondaryButtonColor} !important;
    margin: 4rem 0;
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
    :hover {
      transform: scale(0.98);
      background-color: ${(props) => props.theme.secondaryButtonHover} !important;
    }
  }
  .input-control {
    border-bottom: 1px solid ${(props) => props.theme.lightText};
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    svg {
      fill: ${(props) => props.theme.mediumText};
      font-size: 1.5rem;
      margin-right: 0.5rem;
      height: 11px;
      width: 26px;
    }
    input {
      border: none;
      background: none;
      flex: 1;
      color: ${(props) => props.theme.mediumText};
      padding: 0.5rem;
      font-size: 1.5rem;
      outline: none;
      background-color: transparent !important;
      transition: background-color 10000s, color 10000s;
      &::-webkit-credentials-auto-fill-button {
        background-color: ${(props) => props.theme.lightText} !important;
      }
      &::placeholder {
        color: ${(props) => props.theme.mediumText} !important;
        transition: all 0.3s;
      }
      &:-webkit-autofill {
        color: ${(props) => props.theme.mediumText} !important;
      }
      &:focus {
        &::placeholder {
          transform: translateY(-10px);
          opacity: 0;
        }
      }
    }
  }
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: auto;
  margin: 0 auto;
  & > svg {
    height: auto;
    width: 100%;

    &.circle {
      width: 67.5%;
      & + .text {
        margin-top: 0.5rem;
        margin-left: 1.35rem;
      }
    }
  }
`;
