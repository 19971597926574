import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";

import { Table } from "lib/components/Table";
import { Card } from "lib/components/Card";
import { CTAButton } from "lib/components/CTAButton";
import { Searchbar } from "lib/components/Searchbar";
import { LinkRow } from "lib/components/LinkRow";
import { StyledFilters } from "lib/styles/general";
import { resolveObject } from "lib/helpers/resolveObject";
import { getApiUrl } from "lib/helpers/getApiUrl";

const fetchCampaigns = async () => {
  return await fetchData(`${getApiUrl("REPORTING_API")}/phish-sim/reports`);
};

export const ListCampaigns = () => {
  const [search, setSearch] = useState("");
  const { data, isLoading, error } = useFetch(fetchCampaigns);

  const history = useHistory();
  const handleSubmitNew = () => history.push("/phish-sim/campaigns/new");

  const searchIndex = campaign => {
    const searchFields = ["title", "customer.code", "template.name", "template.difficulty"];
    const searchKeys = searchFields.map(field => resolveObject(field, campaign)?.toString()?.toLowerCase());
    const index = searchKeys.join("");
    return index;
  }
  
  return (
    <>
      <StyledFilters>
        <Searchbar setSearch={setSearch} />
        <hr />
        <CTAButton onClick={handleSubmitNew}>Submit New</CTAButton>
      </StyledFilters>
      {isLoading && <Spinner />}
      {error && <Error message={error} />}
      {data && (
        <Card pad={false}>
          <Table style={{ border: "none" }}>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Title</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Difficulty</th>
                <th>Template Name</th>
                <th>Targets</th>
                <th style={{ width: "2rem", textAlign: "right" }}>Opened</th>
                <th style={{ width: "2rem", textAlign: "right" }}>Clicked</th>
                <th style={{ width: "2rem", textAlign: "right" }}>Submitted</th>
                <th style={{ width: "2rem", textAlign: "right" }}>Reported</th>
              </tr>
            </thead>
            <tbody>
              {data.result
                ?.filter((campaign) => (search ? searchIndex(campaign).includes(search.toLowerCase()) : true))
                ?.sort((a, b) => moment(b.startDate).unix() - moment(a.startDate).unix())
                ?.map((campaign = {}, i) => {
                  const targetPercentage = (i) => !campaign.isBlank ? `${i} (${Math.round((i / campaign.targets?.length) * 100)}%)` : "N/A";
                  return (
                    <LinkRow key={i} path={`/phish-sim/campaigns/single/${campaign.id}`}>
                      <td title={campaign.customer?.name}>{campaign.customer?.code}</td>
                      <td>{campaign.title}</td>
                      <td>{moment(campaign.startDate).format("HH:mm YYYY-MM-DD")}</td>
                      <td>{moment(campaign.endDate).format("HH:mm YYYY-MM-DD")}</td>
                      <td>{campaign.template?.difficulty || 0}/5</td>
                      <td>{campaign.template?.name || "N/A"}</td>
                      <td>{campaign.targets?.length || "N/A"}</td>
                      <td style={{ textAlign: "right" }}>{targetPercentage(campaign.eventTotals?.opened)}</td>
                      <td style={{ textAlign: "right" }}>{targetPercentage(campaign.eventTotals?.clicked)}</td>
                      <td style={{ textAlign: "right" }}>{targetPercentage(campaign.eventTotals?.submitted)}</td>
                      <td style={{ textAlign: "right" }}>{targetPercentage(campaign.eventTotals?.reported)}</td>
                    </LinkRow>
                  );
                })}
            </tbody>
          </Table>
        </Card>
      )}
    </>
  );
};
