import React, { useState, useRef, useContext } from "react";
import { fetchData } from "lib/helpers/fetchData";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { CTAButton } from "lib/components/CTAButton";
import { getFormValues } from "lib/helpers/getFormValues";
import { useEffectExceptOnMount } from "lib/helpers/useEffectExceptOnMount";
import moment from "moment";
import styled from "styled-components";
import { ConfirmModal } from "lib/components/Modal";
import { KeyValue } from "lib/components/KeyValue";
import { CustomersContext, TestersContext } from "..";
import { MarkdownEditor } from "lib/components/MarkdownEditor";
import { csvInjectionCheck } from "lib/helpers/csvInjectionCheck";
import { getApiUrl } from "lib/helpers/getApiUrl";

const upsertEngagement = async ({ engagementId, values }) => {
  const method = engagementId ? "PUT" : "POST";
  const updatePath = `/engagements/${engagementId}`;
  const createPath = `/engagements/create`;
  return fetchData(`${getApiUrl("PENTEST_API")}${engagementId ? updatePath : createPath}`, JSON.stringify(values), method);
};

const getTesterId = (tester) => `${tester.id}${tester.from}${tester.to}`;

export const UpsertEngagement = ({ data: engagement = {}, endCreation, hide, findings = [], attachments = [], setNewCustomerId }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(!engagement.id);
  const formRef = useRef(null);
  const { data: customers } = useContext(CustomersContext);
  const { data: users } = useContext(TestersContext);
  const [testers, setTesters] = useState(engagement.testerDates || []);
  const isCreateMode = isEditMode && !engagement.id;
  const [downloading, setDownloading] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const handleUpsertEngagement = async () => {
    const values = getFormValues(formRef.current);
    const engagementId = engagement?.id;
    values.testerDates = testers;
    values.originalCustomerId = engagement.customer ? engagement.customer.id : "";
    values.execSummary = getTemplate({ engagement, earliestDate, latestDate });
    let newErrors = {};
    if (!values.title) newErrors.title = "Title is required";
    if (!values.projectId) newErrors.projectId = "Project ID is required";
    if (!values.customerId) newErrors.customerId = "Customer is required";
    const { testerDates, ...valuesWithoutTesterDates } = values;
    newErrors = csvInjectionCheck(valuesWithoutTesterDates, newErrors);
    
    if (!Object.keys(newErrors)?.length) {
      setIsLoading(true);
      const newEngagement = await upsertEngagement({ engagementId, values });
      setIsLoading(false);
      setIsEditMode(false);
      endCreation && endCreation(newEngagement.result.id, newEngagement.result.customer.id);
      setNewCustomerId && setNewCustomerId(values.customerId);
    }
    setErrors(newErrors);
  };

  const handleChange = (tester) => {
    const newTesters = [...testers];
    const testerId = getTesterId(tester);
    const existingIndex = newTesters.findIndex((t) => getTesterId(t) === testerId);
    if (existingIndex > -1) {
      newTesters[existingIndex] = tester;
    } else {
      newTesters.push(tester);
    }
    setTesters(newTesters);
  };

  const handleDownload = async () => {
    setDownloading(true);

    const content = await fetchData(`${getApiUrl("PENTEST_API")}/generate-report/${engagement.customer.id}/${engagement.id}`);

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/html;charset=utf-8," + encodeURIComponent(content.result));
    element.setAttribute("download", `${engagement.title}.html`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    setDownloading(false);
  };

  const handleCSVDownload = async () => {
    setDownloadingCSV(true);

    const content = await fetchData(`${getApiUrl("PENTEST_API")}/export-findings/${engagement.customer.id}/${engagement.id}`);

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(content.result));
    element.setAttribute("download", `${engagement.title}.csv`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    setDownloadingCSV(false);
  };

  const handleDelete = (index) => {
    const newTesters = [...testers];
    newTesters.splice(index, 1);
    setTesters(newTesters);
  };

  const handleDeleteEngagement = async () => {
    setIsDeleting(true);
    await fetchData(`${getApiUrl("PENTEST_API")}/engagements/${engagement.customer.id}/${engagement.id}`, null, "DELETE");
    setConfirmModal({ showModal: false, callback: null });
    setIsDeleting(false);
    hide();
  };

  const handleDeleteEngagementPrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDeleteEngagement() });
  };

  const handleCancel = () => endCreation(engagement?.id);

  const replaceAttachments = (content = "") => {
    const regexp = /\[.*\]\((.*) "(.*)"\)/g;
    const matches = [...content.matchAll(regexp)];
    if (!matches || !matches.length) return content;
    for (const match of matches) {
      const [, url, attachmentId] = match;
      const attachment = attachments.find((a) => a.id === attachmentId);
      if (!attachment) return content;
      content = content.replace(url, attachment.url.replaceAll(" ", "%20"));
    }
    return content;
  };

  const toDates = engagement?.testerDates?.map((testerDate) => moment(testerDate.to).unix()) || [];
  const fromDates = engagement?.testerDates?.map((testerDate) => moment(testerDate.from).unix()) || [];
  let latestDate = Math.max(...toDates);
  latestDate = moment.unix(latestDate).format("YYYY-MM-DD");
  let earliestDate = Math.min(...fromDates);
  earliestDate = moment.unix(earliestDate).format("YYYY-MM-DD");
  const hasValidDates = moment(latestDate).isValid() && moment(earliestDate).isValid();
  const hasStarted = moment(earliestDate).isBefore(moment());
  const hasFinished = moment(latestDate).isBefore(moment());
  const isInProgress = hasStarted && !hasFinished;

  const addSynopsesSummaryButton = {
    name: "addSynopses",
    icon: () => (
      <CTAButton as="label" isSecondary style={{ marginTop: "-0.7rem" }}>
        Add Synopses
      </CTAButton>
    ),
    execute: ({ textApi }) => setSynopses(textApi, findings),
  };

  return (
    <StyledContainer style={{ marginBottom: isCreateMode && "1rem" }}>
      {!isCreateMode && (
        <StyledHeader>
          {!isEditMode && (
            <div className="header">
              <h3>{engagement.projectId}</h3>
              <h1>{engagement.title}</h1>
            </div>
          )}
          <div style={{ display: "flex", flex: 1, gap: "1rem", justifyContent: "flex-end" }}>
            {isEditMode ? (
              <CTAButton isSecondary onClick={() => setIsEditMode(false)}>
                Cancel
              </CTAButton>
            ) : (
              <CTAButton
                isSecondary
                onClick={handleDeleteEngagementPrompt}
                style={{ color: "rgba(200, 40, 40, 0.85)", fontWeight: "bold" }}
              >
                Delete
              </CTAButton>
            )}
            <CTAButton onClick={() => (isEditMode ? handleUpsertEngagement() : setIsEditMode(true))} isLoading={isLoading}>
              {isEditMode ? "Save" : "Edit"}
            </CTAButton>
            <CTAButton isLoading={downloading} style={{ width: "12rem" }} onClick={handleDownload}>
              Download Report
            </CTAButton>
            <CTAButton isLoading={downloadingCSV} style={{ width: "12rem" }} onClick={handleCSVDownload}>
              Findings .csv
            </CTAButton>
          </div>
        </StyledHeader>
      )}
      <StyledForm ref={formRef} style={{ width: "100%" }}>
        <section style={{ paddingTop: 0 }}>
          {isEditMode && (
            <span>
              <Input name="title" label="Title" defaultValue={engagement.title} error={errors.title} />
              <Input name="projectId" label="Project Identifier" defaultValue={engagement.projectId} error={errors.projectId} />
            </span>
          )}
          <span>
            <CustomerPicker
              customers={customers}
              readOnly={!isEditMode}
              defaultValue={engagement.customer?.id}
              error={errors?.customerId}
            />
            {!isEditMode && hasValidDates && (
              <Input label="Status" readOnly>
                <p
                  style={{
                    color: isInProgress ? "#01ad01" : !hasFinished && "#ce8d00",
                    margin: 0,
                    marginTop: "0.2rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {isInProgress ? "In progress..." : hasFinished ? "Finished" : "Not yet started"}
                </p>
              </Input>
            )}
          </span>
          {!isEditMode && hasValidDates && (
            <span style={{ margin: 0 }}>
              <KeyValue text="Start Date" value={earliestDate} />
              <KeyValue text="End Date" value={latestDate} />
            </span>
          )}
        </section>
        <section>
          {isCreateMode && <p style={{ margin: "-1rem 0 1rem" }}>(Optional)</p>}
          {isEditMode && <TesterPicker users={users} onChange={handleChange} columnHeaders />}
          {testers.length
            ? testers
                .sort((a, b) => moment(a.from).diff(moment(b.from)))
                .map((tester, index) => (
                  <TesterPicker
                    users={users}
                    tester={tester}
                    key={getTesterId(tester)}
                    onChange={handleChange}
                    onDelete={isEditMode && (() => handleDelete(index))}
                    readOnly
                  />
                ))
            : !isEditMode && <StyledNoLengthMessage>No tester dates added yet</StyledNoLengthMessage>}
        </section>
        <section style={{ paddingBottom: 0 }}>
          {isCreateMode && <p style={{ margin: "-1rem 0 1rem" }}>(Optional)</p>}
          <Input label="Scope of Engagement" error={errors.scope}>
            <MarkdownEditor subtle name="scope" defaultValue={replaceAttachments(engagement.scope)} readOnly={!isEditMode}/>
          </Input>
          <Input label="Objectives" error={errors.objectives}>
            <MarkdownEditor subtle name="objectives" defaultValue={replaceAttachments(engagement.objectives)} readOnly={!isEditMode}/>
          </Input>
          {!isCreateMode && (
            <Input label="Exec Summary">
              <MarkdownEditor
                subtle
                name="execSummary"
                defaultValue={replaceAttachments(engagement.execSummary)}
                readOnly={!isEditMode}
                commands={[addSynopsesSummaryButton]}
              />
            </Input>
          )}
        </section>
      </StyledForm>
      {isCreateMode && (
        <StyledFooter create={isCreateMode}>
          <CTAButton isSecondary onClick={handleCancel}>
            Cancel
          </CTAButton>
          <CTAButton onClick={handleUpsertEngagement} isLoading={isLoading}>
            Create
          </CTAButton>
        </StyledFooter>
      )}
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.3rem;
  position: relative;

  & > .header {
    position: absolute;
    h3 {
      margin: 0;
      font-size: 1.2rem;
      font-weight: normal;
      color: ${(props) => props.theme.lightText};
    }
    h1 {
      margin: 0;
      font-size: 1.65rem;
      font-weight: normal;
      color: ${(props) => props.theme.text};
    }
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 1rem;
`;

const StyledContainer = styled.div`
  position: relative;
  padding: 0 1rem;
`;

const StyledNoLengthMessage = styled.p`
  font-size: 1.5rem;
  color: ${(props) => props.theme.mediumText};
  text-align: center;
  margin: 0;
`;

const CustomerPicker = ({ customers, readOnly, defaultValue, error }) => {
  return (
    <Input label="Customer" error={error}>
      <Select
        name="customerId"
        readOnly={readOnly}
        style={{ marginTop: "0.3rem", marginBottom: "1rem", width: "100%" }}
        defaultValue={defaultValue}
      >
        <option value="">Select customer</option>
        {customers?.result
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
      </Select>
    </Input>
  );
};

const TesterPicker = ({ tester = {}, users, onChange, onDelete, readOnly, columnHeaders }) => {
  const [testerId, setTesterId] = useState(tester.id);
  const [startDate, setStartDate] = useState(tester.from);
  const [endDate, setEndDate] = useState(tester.to);
  const [refreshIndex, setRefreshIndex] = useState(0);
  const [errors, setErrors] = useState({});

  useEffectExceptOnMount(() => {
    if (onDelete) {
      handleChange();
    }
  }, [testerId, startDate, endDate]);

  const handleChange = () => {
    let newErrors = {};
    if (!testerId) newErrors.testerId = " ";
    if (!startDate) newErrors.startDate = " ";
    if (!endDate) newErrors.endDate = " ";
    if (moment(endDate).isBefore(moment(startDate))) newErrors.endDate = " ";
    setErrors(newErrors);

    if (!Object.keys(newErrors).length){
      setRefreshIndex(refreshIndex + 1);
      const newTester = {
        id: testerId,
        from: startDate,
        to: endDate,
      };
      onChange(newTester);
      setTesterId("");
      setStartDate("");
      setEndDate("");
    }

  };

  return (
    <StyledTesterDates key={refreshIndex}>
      <ColumnHeader header="Tester" error={errors?.testerId} columnHeaders={columnHeaders} onChange={(e) => setTesterId(e.target.value)}>
        <Select defaultValue={tester.id} readOnly={readOnly}>
          <option value="">Select tester</option>
          {users?.result
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((tester) => (
              <option key={tester.id} value={tester.id}>
                {tester.name}
              </option>
            ))}
        </Select>
      </ColumnHeader>
      <ColumnHeader
        header="Start date"
        error={errors.startDate}
        columnHeaders={columnHeaders}
        onChange={(e) => setStartDate(e.target.value)}
      >
        <Input type="date" defaultValue={startDate && moment(startDate).format("YYYY-MM-DD")} readOnly={readOnly} />
      </ColumnHeader>
      <ColumnHeader header="End date" error={errors.endDate} columnHeaders={columnHeaders} onChange={(e) => setEndDate(e.target.value)}>
        <Input type="date" defaultValue={endDate && moment(endDate).format("YYYY-MM-DD")} readOnly={readOnly} />
      </ColumnHeader>
      {onDelete ? (
        <CTAButton isSecondary onClick={onDelete}>
          Remove
        </CTAButton>
      ) : (
        !readOnly && (
          <CTAButton isSecondary onClick={handleChange}>
            Add tester
          </CTAButton>
        )
      )}
    </StyledTesterDates>
  );
};

const ColumnHeader = ({ columnHeaders, header, children, ...props }) => {
  if (!columnHeaders) return children;
  return (
    <Input label={header} {...props}>
      {children}
    </Input>
  );
};

const StyledTesterDates = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  input {
    margin: 0 !important;
  }
  button {
    margin: 0 !important;
    height: 2.9rem;
    border-color: ${(props) => props.theme.headerOutline} !important;
  }
  &:first-child button {
    margin-top: 0.6rem !important;
  }
  &:not(:first-child) {
    margin-top: 0.6rem !important;
  }
  select {
    margin: 0 !important;
    height: 2.9rem;
  }
  svg {
    padding: 0.8rem !important;
  }
  & > label {
    flex: 1;
    color: ${(props) => props.theme.mediumText};
    margin-bottom: 1rem;
    &:not(:first-child) {
      flex: unset;
      & > label {
        flex: unset;
      }
    }
    & > * {
      margin-top: 0.3rem !important;
      margin-left: -0.2rem !important;
    }
  }
  p:read-only {
    margin-left: 0.7rem !important;
    font-size: 1.33rem !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    & + label {
      margin: 0 !important;
      input.labeled-input {
        font-size: 1.33rem !important;
        margin-right: 1.2rem !important;
      }
      & + label {
        margin: 0 !important;
        input.labeled-input {
          font-size: 1.33rem !important;
          margin-right: 0.8rem !important;
        }
      }
    }
  }
`;

export const getTemplate = ({ earliestDate, latestDate, engagement }) => {
  return [
    `Quorum Cyber were engaged by ${engagement?.customer?.name} to perform a web application assessment of ${engagement.title}. This assessment was performed between ${earliestDate} and ${latestDate}. The assessment identified several issues in the implementation of the application, several of which were considered to pose a high to critical risk to ${engagement?.customer?.name} business interests.`,
    `Finally, several vulnerabilities existed which were rated as medium and informational-risk in the wider context - as detailed in sections 5 through to 15. However, Quorum Cyber recommend addressing those to further reduce the risk of the application, its users, data, and underlying systems from being exploited.`,
  ].join("\n\n");
};

export const setSynopses = (textApi, findings) => {
  const currentText = textApi.textAreaRef.current.value;
  const synopses = findings
    .map((finding) => finding.synopsis)
    .filter(Boolean)
    .join("\n\n");
  const [firstParagraph, ...paragraphs] = currentText.split("\n\n");
  const newText = [firstParagraph, synopses, paragraphs].join("\n\n");
  textApi.textAreaRef.current.value = newText;
};
