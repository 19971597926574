import React, { useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAsync } from "react-async";

import { fetchData } from "lib/helpers/fetchData";
import { KeyValue } from "lib/components/KeyValue";
import { CTAButton } from "lib/components/CTAButton";
import { Dropdown } from "lib/components/Dropdown";
import { Tag } from "lib/components/Tag";
import { PreviewBox } from "lib/components/PreviewBox";
import { SelectButton } from "lib/components/SelectButton";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { toSentenceCase } from "lib/helpers/toSentenceCase";
import { hasEvaluationOrigin, getEvaluationOriginIcon, getEvaluationText } from "lib/helpers/getEvaluation";
import { getSeverityText } from "lib/helpers/getSeverityText";
import { getAnalystName } from "lib/helpers/getAnalystName";
import { useUser } from "auth";
import { getApiUrl } from "lib/helpers/getApiUrl";

const claimTicketFn = async ([customerId, ticketId]) => {
  return await fetchData(`${getApiUrl("PHISH_API")}/tickets/${customerId}/${ticketId}/claim`, null, "POST");
};

const closeTicketFn = async ([customerId, ticketId]) => {
  return await fetchData(`${getApiUrl("PHISH_API")}/tickets/${customerId}/${ticketId}/close`, null, "POST");
};

const dismissTicketFn = async ([customerId, ticketId]) => {
  return await fetchData(`${getApiUrl("PHISH_API")}/tickets/${customerId}/${ticketId}/dismiss`, null, "POST");
};

const freezeTicketFn = async ([customerId, ticketId]) => {
  return await fetchData(`${getApiUrl("PHISH_API")}/tickets/${customerId}/${ticketId}/freeze`, null, "POST");
};

const updateTicketFn = async ([customerId, ticketId, tags, severity]) => {
  return await fetchData(
    `${getApiUrl("PHISH_API")}/tickets/${customerId}/${ticketId}/update`,
    JSON.stringify({ severity, tags }),
    "POST"
  );
};

const StyledContainer = styled.div`
  flex: 1;
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 5px;
  padding: 1.5rem;
  min-width: 50%;
  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    padding: 0;
    padding-bottom: 1.5rem;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

export const Evaluate = ({ data, setData, refresh, tagsState = {} }) => {
  const history = useHistory();

  const { data: tags, isLoading: isFetchingTags } = tagsState;
  const { run: claimTicket, isLoading: isClaiming } = useAsync({ deferFn: claimTicketFn, onResolve: refresh });
  const { run: closeTicket, isLoading: isClosing } = useAsync({ deferFn: closeTicketFn, onResolve: refresh });
  const { run: updateTicket, isLoading: isUpdating } = useAsync({ deferFn: updateTicketFn, onResolve: refresh });
  const { run: freezeTicket, isLoading: isFreezing } = useAsync({ deferFn: freezeTicketFn, onResolve: refresh });
  const { run: dismissTicket, isLoading: isDismissing } = useAsync({ deferFn: dismissTicketFn, onResolve: refresh });

  const handleClaimClick = () => claimTicket(data.customer.id, data.id);
  const handleCloseClick = () => closeTicket(data.customer.id, data.id);
  const handleFreezeClick = () => freezeTicket(data.customer.id, data.id);
  const handleDismissClick = () => dismissTicket(data.customer.id, data.id);
  const handleEvaluation = (e) => updateTicket(data.customer.id, data.id, null, { score: parseInt(e) });

  const handleAddTag = (e) => {
    const newTags = [...(data.tags || []), JSON.parse(e)];
    setData({ ...data, tags: newTags });
    updateTicket(data.customer.id, data.id, newTags);
  };

  const handleDeleteTag = (id) => {
    const newTags = data.tags.filter((tag) => tag.id !== id);
    setData({ ...data, tags: newTags });
    updateTicket(data.customer.id, data.id, newTags);
  };

  const handleEvaluationClick = ({ target } = {}) => {
    if (!target?.type || target?.type?.toLowerCase() === "mail" || target?.type?.toLowerCase() === "ticket") return;
    history.push(`/phish/tickets/${data.customer.id}/${data.id}/artifacts/${target.id}`);
  };

  const handleStatusClick = (status) => {
    const actions = {
      suspend: handleFreezeClick,
      dismiss: handleDismissClick,
      close: handleCloseClick,
    };
    const action = actions?.[status];
    action && action();
  };

  const claimAttemptsRef = useRef(0);
  if (!data.analyst && !isClaiming) {
    if (claimAttemptsRef.current < 2) {
      claimTicket(data.customer.id, data.id);
      claimAttemptsRef.current++;
    }
  }

  const getStatusActions = ({ status: _status, severity: _severity }) => {
    let status = _status.toLowerCase();
    let severity = _severity.score;

    let statusActions = [];
    if (status !== "closed" && severity > 0) statusActions.push("close");
    if (status === "open" || status === "suspended") statusActions.push("dismiss");
    if (status === "open") statusActions.push("suspend");
    return statusActions;
  };

  const { user } = useUser();
  const isOwn = user.email.toLowerCase() === data?.analyst?.email.toLowerCase();
  const statusActions = getStatusActions(data);
  const isStatusLoading = isClosing || isDismissing || isFreezing;

  return (
    <StyledContainer>
      <div>
        <KeyValue text="Analyst" value={getAnalystName(data?.analyst)} />
        {!isOwn && (
          <CTAButton onClick={handleClaimClick} isLoading={isClaiming}>
            Claim
          </CTAButton>
        )}
      </div>
      <div>
        <KeyValue text="Status" value={data.status} />
        <SelectButton callback={handleStatusClick} isLoading={isStatusLoading}>
          {statusActions.map((action) => (
            <option key={action} value={action}>
              {toSentenceCase(action)}
            </option>
          ))}
        </SelectButton>
      </div>
      <div>
        <KeyValue
          text="Current Evaluation"
          xHoverPos="left"
          yHoverPos="center"
          inlineIcon
          action={hasEvaluationOrigin(data) && "origin"}
          icon={getEvaluationOriginIcon(data.evaluationOrigin)}
          hoverText={getEvaluationText(data.evaluationOrigin, data)}
          clickAction={() => handleEvaluationClick(data.evaluationOrigin)}
          color={getColorFromSeverity(data.severity)}
          value={getSeverityText(data)}
        />
        <Dropdown label={!data.severity.score ? "Evaluate" : "Re-Evaluate"} callback={handleEvaluation} isLoading={isUpdating}>
          <option value="1">Benign</option>
          <option value="2">Suspicious</option>
          <option value="3">Malicious</option>
        </Dropdown>
      </div>
      <div>
        <KeyValue
          text="Tags"
          wrap={false}
          value={
            <div className="container" style={{ display: "flex", flexWrap: "wrap" }}>
              {data.tags.length > 0 ? (
                data.tags.map((tag) => <Tag key={tag.id} name={tag.name} callback={() => handleDeleteTag(tag.id)} />)
              ) : (
                <p>None</p>
              )}
            </div>
          }
        />
        <Dropdown label="+ Tags" callback={handleAddTag} isLoading={isFetchingTags}>
          {tags?.result
            .filter((tag) => !data.tags.map((t) => t.id).includes(tag.id))
            .map(({ id, name }) => (
              <option key={id} value={JSON.stringify({ id, name })}>
                {name}
              </option>
            ))}
        </Dropdown>
      </div>
      <div>
        <KeyValue
          text="Preview"
          wrap={false}
          style={{ margin: 0 }}
          value={
            <PreviewBox maxHeight={150} id="ticket-eval">
              <img src={data.mail.preview?.url} alt="Evaluate Preview" style={{ width: "100%", height: "auto" }} />
            </PreviewBox>
          }
        />
      </div>
    </StyledContainer>
  );
};
