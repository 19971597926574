import React from "react";
import { CTAButton } from "lib/components/CTAButton";
import { Input } from "lib/components/Input";
import { fetchData } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const SubmitIoCToSentinel = ({ customerId, reload }) => {
  let eventId = React.createRef();
  const handleSubmitToAzureSentinel = async () => {

    try {
      var response = await fetchData(`${getApiUrl('BRAIN_API')}/sentinel/push/misp/${eventId.current.value}`, null, 'POST');
      var results = await response.result;
      if (results) {
        //We know the request went through but we aren't getting a proper response back from the API
        eventId.current.value = '';
      }
    } catch (e) {
      console.log("Error" + e);
    };

  };
  return (
    <div style={{ display: "center", justifyContent: "flex-Left", alignItems: "center", marginBottom: "1rem" }}>
      <form>
        <Input ref={eventId} name="MISPEventID" label="Event ID - **Currently hardcoded for Quorum Cyber only**" defaultValue='' />
        <CTAButton onClick={handleSubmitToAzureSentinel}>Submit</CTAButton>
      </form>
    </div>
  );
};
