import React, { useRef, useState } from "react";

import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { getFormValues } from "lib/helpers/getFormValues";
import { fetchData } from "lib/helpers/fetchData";
import { ErrorModal } from "lib/components/Modal";
import { useIsFeatureEnabled, flags } from "lib/feature-flagging/treatments";
import { getApiUrl } from "lib/helpers/getApiUrl";

export const updateCustomer = async ({ customerId, name, code, usingIdem }) => {
  if (usingIdem)
    return fetchData(`${getApiUrl("IDEM_API")}/organisations/${customerId}`, JSON.stringify({ name, nickname: code }), "PUT");
  else return fetchData(`${getApiUrl("ADMIN_API")}/customers/${customerId}/name`, JSON.stringify({ name, code }), "PUT");
};

export const General = ({ data, handleDelete, reload }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editError, setEditError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const usingIdem = useIsFeatureEnabled(flags.MANAGE_ORGANISATIONS_WITH_IDEM);

  const handleSubmit = async ({ name, code }) => {
    const customerId = data.id;
    setIsEditing(true);
    setEditError(null);
    updateCustomer({ customerId, name, code, usingIdem })
      .then(() => {
        setIsEditing(false);
        reload();
      })
      .catch((e) => {
        setIsEditing(false);
        setEditError(e?.error?.message || "");
      });
  };
  return (
    <GeneralForm
      data={data}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      error={editError}
      setError={setEditError}
      isEditing={isEditing}
    />
  );
};

const GeneralForm = ({ data = {}, isEditMode = true, setIsEditMode, onSubmit, onDelete, error, setError, isEditing }) => {
  const formRef = useRef(null);
  const usingIdem = useIsFeatureEnabled(flags.MANAGE_ORGANISATIONS_WITH_IDEM);

  const handleSubmit = () => {
    const values = getFormValues(formRef.current);
    setIsEditMode && setIsEditMode(false);
    onSubmit && onSubmit(values);
  };
  const handleExit = () => {
    setIsEditMode && setIsEditMode(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {error !== null && <ErrorModal error={error} hide={() => setError(null)} />}
      {!isEditMode && setIsEditMode && (
        <CTAButton
          isLoading={isEditing}
          style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }}
          onClick={() => setIsEditMode(!isEditMode)}
        >
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section style={{ paddingTop: 0 }}>
          <Input name="name" label="Name" defaultValue={data.name} readOnly={!isEditMode} />
          <Input name="code" label="Code" defaultValue={data.code} readOnly={!isEditMode} />
        </section>
        <section>
          {/* eslint-disable-next-line */}
          <h1>Danger Zone 🚨🚨</h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid rgba(255, 0, 0, 0.6)",
              borderRadius: "0.8rem",
              padding: "1rem",
            }}
          >
            <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this customer permanently</p>
            <CTAButton onClick={onDelete} style={{ color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold" }} isSecondary>
              Delete
            </CTAButton>
          </div>
        </section>
      </StyledForm>
      {usingIdem && (
        <h2>
          Please note: After updating or deleting a customer, it may take a few minutes for the changes to reflect in the user interface.
          You may need to refresh the page to see the updated customer information in the left pane.
        </h2>
      )}
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton onClick={handleSubmit}>{setIsEditMode ? "Save" : "Create"}</CTAButton>
        </div>
      )}
    </div>
  );
};
