import { useState, forwardRef } from "react";
import styled from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faEnvelope, faFolder, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CachedImage } from "lib/components/CachedImage";
import { ConfirmModal, ErrorModal } from "lib/components/Modal";
import { fetchData } from "lib/helpers/fetchData";
import { Spinner } from "lib/components/Spinner";
import { getApiUrl } from "lib/helpers/getApiUrl";

const StyledAttachmentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 0.5rem;
  font-size: 1.33rem;
  width: 100%;
  a {
    text-decoration: none;
  }
  & > p {
    font-size: 1.5rem;
    color: ${(props) => props.theme.mediumText};
    text-align: center;
    grid-column: 1 / span 2;
  }
`;

export const StyledAttachment = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 18rem;
  border-radius: 0.33rem;
  background: ${(props) => props.theme.cardBackground};
  transition: background 0.3s;
  cursor: pointer;
  position: relative;
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
    .delete-icon {
      opacity: 1;
    }
  }
  &&& > p {
    margin: 0.2rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.theme.accentText} !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    font-size: inherit;
  }
`;

export const StyledPreview = styled.div`
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.33rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  svg {
    font-size: 4rem;
    color: gray;
  }
  img {
    max-width: 100%;
    max-height: 14rem;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    color: ${(props) => props.theme.mediumText};
    font-size: 1.33rem;
    margin: 0;
  }
`;

const StyledDeleteIcon = styled.div`
  opacity: 0;
  transition: all 0.1s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5rem;
  padding: 0.5rem;
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
    border-color: ${(props) => props.theme.accentText};
  }
`;

export const getPreviewImage = (type, url) => {
  switch (type) {
    case "image/png":
      return <CachedImage src={url} alt="attachment" />;
    case "image/jpeg":
      return <CachedImage src={url} alt="attachment" />;
    case "image/jpg":
      return <CachedImage src={url} alt="attachment" />;
    case "image/gif":
      return <CachedImage src={url} alt="attachment" />;
    case "text/csv":
      return <FontAwesomeIcon icon={faFileCsv} />;
    case "message/rfc822":
      return <FontAwesomeIcon icon={faEnvelope} />;
    default:
      return <FontAwesomeIcon icon={faFolder} />;
  }
};

export const Preview = ({ type, url, isPlaceholder }) => {
  return <StyledPreview>{isPlaceholder ? <Spinner /> : getPreviewImage(type, url)}</StyledPreview>;
};

const deleteAttachment = ({ attachmentId, targetId }) => {
  return fetchData(`${getApiUrl("PENTEST_API")}/attachments/${targetId}/${attachmentId}`, null, "DELETE");
};

export const AttachmentsBlock = forwardRef(({ attachments = [], reload }, ref ) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  const handleDeleteAttachment = async (attachmentId, targetId) => {
    setIsDeleting(true);
    try {
      await deleteAttachment({ attachmentId, targetId });
      reload();
    } catch (e) {
      setError(e.message);
    }
    setIsDeleting(false);
    setConfirmModal({ showModal: false, callback: null });
  };

  const handleDeleteClick = (e, attachmentId, targetId) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmModal({ showModal: true, callback: () => handleDeleteAttachment(attachmentId, targetId) });
  };

  const handleDragStart = (e, attachment) => {
    const { filename, url, id } = attachment;
    const data = { filename, link: url, id };
    if (attachment.type.includes("image")) {
      data.image = url;
    }
    e.dataTransfer.setData("data", JSON.stringify(data));
  };

  return (
    <StyledAttachmentsContainer ref={ref} >
      {attachments.map((attachment) => {
        return (
          <a
            draggable
            onDragStart={(e) => handleDragStart(e, attachment)}
            key={attachment.id}
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledAttachment title={attachment.filename}>
              <Preview type={attachment.type} url={attachment.url} isPlaceholder={attachment.isPlaceholder} />
              <p>{attachment.filename}</p>
              <StyledDetails>
                <p>{moment.utc(attachment.created).format("HH:mm - MMM Do")}</p>
              </StyledDetails>
              <StyledDeleteIcon className="delete-icon" onClick={(e) => handleDeleteClick(e, attachment.id, attachment.targetId)}>
                <FontAwesomeIcon icon={faTrash} />
              </StyledDeleteIcon>
            </StyledAttachment>
          </a>
        );
      })}
      <ConfirmModal isLoading={isDeleting} {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
      {error !== null && <ErrorModal error={error} hide={() => setError(null)} />}
    </StyledAttachmentsContainer>
  );
});
